/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'material-kit/components/Button';
import Price from 'material-kit/components/Price';
import { CartContext } from 'cart/constants';
import reduce from 'lodash/reduce';
import { add, format } from 'layout/util/moneyUtils';
import CartPricingSummaryScss from './CartPricingSummary.scss';
import GlobalScss from '../../../layout/style/common.scss';
import PromoCodeForm from '../PromoCodes/PromoCodeForm';
import PromoCodes from '../PromoCodes/PromoCodes';
import { trackCartToCheckout } from '../../../analytics/events';

const CartPricingSummary = ({
  anonymous,
  context = CartContext.Cart,
  fulfillmentGroup,
  itemAdjustmentsValue,
  itemCount,
  onPerformCheckout,
  orderItem,
  subTotal,
  totalAdjustmentsValue,
  totalTax,
  totalShipping,
  total,
  addedOfferCodes,
  addPromo,
  removePromo,
  orderAdjustmentsValue,
  totalBundleChildrenAdjustmentValue,
  location
}) => {
  return (
    <div
      className="card"
      styleName="CartPricingSummaryScss.CartPricingSummary_component"
    >
      <div styleName="CartPricingSummaryScss.CartPricingSummary">
        {context !== CartContext.Confirmation /*&& !location?.pathname.includes('/checkout/review')*/ && (
          <PromoCodes
            addedOfferCodes={addedOfferCodes}
            addPromo={addPromo}
            removePromo={removePromo}
          />
        )}

        {context !== CartContext.Confirmation && (
          <div styleName="GlobalScss.CardPricing__title">
            <h4>Summary</h4>
          </div>
        )}
        <div styleName="CartPricingSummaryScss.CartPricingSummary__breakdown">
          {/* <!-- Subtotal --> */}
          <div styleName="CartPricingSummaryScss.CartPricingSummary__prices">
            <span>SubTotal</span>
            <Price
              className="pull-right"
              price={
                itemAdjustmentsValue || totalBundleChildrenAdjustmentValue
                  ? add(add(subTotal, itemAdjustmentsValue),totalBundleChildrenAdjustmentValue)
                  : subTotal
              }
            />
          </div>

          {/* <!-- Total Savings --> */}
          <div styleName="CartPricingSummaryScss.CartPricingSummary__prices">
            <span>Vouchers</span>
            <span className="pull-right">{format(add(add(orderAdjustmentsValue,itemAdjustmentsValue),totalBundleChildrenAdjustmentValue).amount, orderAdjustmentsValue.currency)}</span>
          </div>

          {/* <!-- Taxes --> 
                    {context !== CartContext.Cart && (
                        <div styleName='CartPricingSummaryScss.CartPricingSummary__prices'>
                            <span>Taxes</span>
                            <Price className='pull-right' price={totalTax} />
                        </div>
                    )}*/}

          {/* <!-- Total Shipping --> */}

          <div styleName="CartPricingSummaryScss.CartPricingSummary__prices">
            <span>Shipping</span>
            <Price className="pull-right" price={totalShipping} />
          </div>

          {/* <!-- Estimated Total --> */}
          {context === CartContext.Cart && (
            <div styleName="CartPricingSummaryScss.CartPricingSummary__estimatedTotal">
              <span>Total (taxes incl.)</span>
              <Price className="pull-right" price={total} />
            </div>
          )}

          {/* <!-- Total --> */}
          {context !== CartContext.Cart &&
            context !== CartContext.Confirmation && (
              <div styleName="CartPricingSummaryScss.CartPricingSummary__total">
                <span>Total (taxes incl.)</span>
                <Price className="pull-right" price={total} />
              </div>
            )}
          {context == CartContext.Confirmation && (
            <div styleName="CartPricingSummaryScss.CartPricingSummary__total">
              <span>Total Paid</span>
              <Price className="pull-right" price={total} />
            </div>
          )}
          {/* <!-- Cart related actions --> */}
          {itemCount > 0 && context === CartContext.Cart && (
            <div styleName="CartPricingSummaryScss.CartPricingSummary__actions">
              <div>
                <Button
                  onClick={(e) => trackCartToCheckout()}
                  primary
                  component={Link}
                  componentProps={{
                    to: {
                      pathname: anonymous ? '/checkout/login' : '/checkout',
                      state: {
                        from: '/checkout',
                      },
                    },
                  }}
                >
                  Checkout
                </Button>
              </div>
              {/*
                        <div styleName='Divider'>or</div>
                        <div>
                            <input type="image" src="https://www.paypal.com/en_US/i/btn/btn_xpressCheckout.gif" alt="Submit Form" />
                        </div>*/}
            </div>
          )}
          {context === CartContext.Checkout && (
            <div styleName="CartPricingSummaryScss.CartPricingSummary__actions"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartPricingSummary;
