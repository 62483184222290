/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesDesktopFlours(props) {
  const {
    image,
    firstDot,
    secondDot,
    thirdDot,
    fourthDot,
    sliderImage,
    overrides,
    ...rest
  } = props;
  const buttonFiveSevenThreeFiveOneThreeFourFiveTwoOnClick = useNavigateAction({
    type: "url",
    url: "/flours",
  });
  return (
    <Flex
      gap="40px"
      direction="row"
      width="unset"
      height="600px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="8px"
      padding="64px 64px 64px 64px"
      backgroundColor="rgba(252,242,233,1)"
      {...getOverrideProps(overrides, "CategoriesDesktopFlours")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="550px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 508")}
      >
        <Flex
          gap="40px"
          direction="column"
          width="650px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 505")}
        >
          <Flex
            gap="35px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 502")}
          >
            <Flex
              gap="2px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 496")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 507")}
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize="36px"
                  fontWeight="700"
                  color="rgba(21,71,38,1)"
                  lineHeight="54px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.35px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Stone-Milled Flours"
                  {...getOverrideProps(overrides, "Title")}
                ></Text>
              </Flex>
              <Text
                fontFamily="Montserrat"
                fontSize="18px"
                fontWeight="500"
                color="rgba(21,71,38,1)"
                lineHeight="27px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.35px"
                width="550px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Experience the freshness of stone-ground whole grain flours, crafted in Ireland for quality, sustainability, and taste. Packed with nutrients, fiber, flavor, and texture, these flours make every dish wholesome and delicious, ideal for your baking and cooking needs."
                {...getOverrideProps(overrides, "Text573513429")}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="593px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 501")}
            >
              <Flex
                gap="20px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One")}
              >
                <Flex
                  gap="23px"
                  direction="row"
                  width="437px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513432")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="22px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513433")}
                  >
                    <Image
                      width="unset"
                      height="22px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={firstDot}
                      {...getOverrideProps(overrides, "Image573513434")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Stone-Milled for superior flavor & texture."
                    {...getOverrideProps(overrides, "Text573513435")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="24px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Two")}
              >
                <Flex
                  gap="24px"
                  direction="row"
                  width="591px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513437")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="33px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513438")}
                  >
                    <Image
                      width="unset"
                      height="33px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={secondDot}
                      {...getOverrideProps(overrides, "Image573513439")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="More nutrients are retained."
                    {...getOverrideProps(overrides, "Text573513440")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="26px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Three")}
              >
                <Flex
                  gap="26px"
                  direction="row"
                  width="593px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513442")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="35px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513443")}
                  >
                    <Image
                      width="unset"
                      height="35px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={thirdDot}
                      {...getOverrideProps(overrides, "Image573513444")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Freshly milled in-house in small batches."
                    {...getOverrideProps(overrides, "Text573513445")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="26px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Four")}
              >
                <Flex
                  gap="26px"
                  direction="row"
                  width="593px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513447")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="19.2px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513448")}
                  >
                    <Image
                      width="unset"
                      height="19.2px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={fourthDot}
                      {...getOverrideProps(overrides, "Image573513449")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="No preservatives, just pure whole grain flour."
                    {...getOverrideProps(overrides, "Text573513450")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="29px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 504")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="153px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button573513572")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                alignSelf="stretch"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Browse Flours"
                onClick={() => {
                  buttonFiveSevenThreeFiveOneThreeFourFiveTwoOnClick();
                }}
                {...getOverrideProps(overrides, "Button573513452")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        width="468px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Image573513454")}
      >
        <Image
          width="unset"
          height="425.92px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={image}
          {...getOverrideProps(overrides, "Image573513575")}
        ></Image>
      </Flex>
    </Flex>
  );
}
