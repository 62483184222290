/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import Carouselindicator from "./Carouselindicator";
import { Flex } from "@aws-amplify/ui-react";
export default function Bannersefertest(props) {
  const {
    onSecondDotClick,
    onFirstDotClick,
    onThirdDotClick,
    onFourthDotClick,
    selectedIndex,
    contentSize = 4,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="64px"
      direction="row"
      width="1920px"
      height="unset"
      justifyContent="center"
      alignItems="flex-end"
      position="relative"
      borderRadius="0px 0px 16px 16px"
      padding="64px 16px 64px 16px"
      {...getOverrideProps(overrides, "Bannersefertest")}
      {...rest}
    >
      <Carouselindicator
        display="flex"
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 64px 16px 64px"
        backgroundColor="rgba(221,206,198,1)"
        contentSize={contentSize}
        onSecondDotClick={onSecondDotClick}
        onFirstDotClick={onFirstDotClick}
        onThirdDotClick={onThirdDotClick}
        onFourthDotClick={onFourthDotClick}
        selectedIndex={selectedIndex}
        {...getOverrideProps(overrides, "Carouselindicator")}
      ></Carouselindicator>
    </Flex>
  );
}
