import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPageByUrl } from '../common/actions/pageActions';
import GlobalScss from '../../layout/style/common.scss';
import CheckoutField from '../../checkout/components/CheckoutField/CheckoutField';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import Button from 'material-kit/components/Button';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

class CookiePolicy extends Component {
  state = {
    emailSuccess: false,
    content: '',
  };

  static propTypes = {
    pageData: PropTypes.object,
    fetchPageByUrl: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { match, fetchPageByUrl } = this.props;
    this.fetchHtmlContent();
  }

  fetchHtmlContent() {
    fetch(
      'https://uat-reddotfood-eu-static-pages-bucket.s3.eu-west-1.amazonaws.com/static/cookie-policy/html/cookie-policy.html'
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((content) => {
        this.setState({ content });
      })
      .catch((error) => {
        console.error('Error fetching HTML content:', error);
      });
  }

  render() {
    const { content } = this.state;
    return (
      <div className="container row" styleName="GlobalScss.StaticPage_content">
        <Helmet titleTemplate="%s | Reddot Food Store">
          <title>Cookie Policy</title>
          <meta name="description" content="Account" />
          <link rel="canonical" href="/cookies" />
        </Helmet>
        <div className="col-xs-6" styleName="GlobalScss.StaticPage_Title">
          <h4>Cookie Policy</h4>
        </div>
        <div>{ReactHtmlParser(content)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pageData: state.page[ownProps.match.url],
});

const mapDispatchToProps = {
  fetchPageByUrl,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookiePolicy);
