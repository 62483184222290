/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import SandboxRibbon from 'preview/components/SandboxRibbon';
import CsrRibbon from 'csr/components/CsrRibbon';
import FullPageLayout from 'layout/components/FullPageLayout';
import Category from 'catalog/category/components/Category';
import Product from 'catalog/product/components/Product';
import Search from 'catalog/search/components/Search';
import Cart from 'cart/components/Cart';
import Checkout from 'checkout/components/Checkout';
import Home from 'page/home/components/Home';
import Confirmation from 'checkout/components/Confirmation';
import Account from 'account/components/Account';
import NotFound from 'layout/components/NotFound';
import { isAuthenticated, isAnonymous } from 'auth/selectors';
import ForgotPassword from '../../auth/components/LoginRegister/ForgotPassword';
import connectPage from 'page/common/decorators/connectPage';
import ContactUs from '../../page/ContactUs/ContactUs';
import Faq from '../../page/Faq/Faq';
import Register from '../../auth/components/LoginRegister/Register';
import Login from '../../auth/components/LoginRegister/Login';
import TermsAndConditions from '../../page/TermsAndConditions/TermsAndConditions';
import ShippingAndRefundPolicy from '../../page/ShippingAndRefundPolicy/ShippingAndRefundPolicy';
import BulkOrders from '../../page/BulkOrders/BulkOrders';
import GeolocationProvider from './GeoLocationProvider';
import { ThemeProvider } from '@aws-amplify/ui-react';
import extendedStudioTheme from '../../ui-components/extendedStudioTheme';
import PrivacyPolicy from '../../page/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from '../../page/CookiePolicy/CookiePolicy';

const ScrollToHash = ({ location }) => {
  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.split('#')[1];
      const scrollToElement = () => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      };

      const handleLoad = () => {
        scrollToElement(); // Pokreni skrolovanje čim je stranica potpuno učitana
      };

      if (document.readyState === 'complete') {
        // Ako je stranica već učitana
        scrollToElement();
      } else {
        // Dodaj event listener da sačeka dok se stranica ne učita
        window.addEventListener('load', handleLoad);
      }

      // Očisti event listener kada komponenta bude demontirana
      return () => window.removeEventListener('load', handleLoad);
    }
  }, [location.hash]);

  return null;
};

const ScrollToHashWithRouter = withRouter(ScrollToHash);

const PrivateRoute = connect((state) => {
  return {
    authenticated: isAuthenticated(state),
    anonymous: isAnonymous(state),
  };
})(({ component: Component, anonymous, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated && !anonymous ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
));

const Root = () => (
  <GeolocationProvider>
    <div>
      <ThemeProvider theme={extendedStudioTheme}>
        <SandboxRibbon />
        <CsrRibbon />
        <ScrollToHashWithRouter /> {/* Add ScrollToHash component here */}
        <Switch>
          <PrivateRoute path="/account" component={Account} />
          <Route
            path="/"
            render={() => (
              <FullPageLayout>
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={(props) => <Home {...props} />}
                  />
                  <Route path="/contactus" component={connectPage(ContactUs)} />
                  <Route
                    path="/bulk-orders"
                    component={connectPage(BulkOrders)}
                  />
                  <Route
                    path="/terms"
                    component={connectPage(TermsAndConditions)}
                  />
                  <Route
                    path="/privacy"
                    component={connectPage(PrivacyPolicy)}
                  />
                  <Route
                    path="/cookies"
                    component={connectPage(CookiePolicy)}
                  />
                  <Route
                    path="/shipping-refund"
                    component={connectPage(ShippingAndRefundPolicy)}
                  />
                  <Route path="/faq" component={connectPage(Faq)} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/register" exact component={Register} />
                  <Route
                    path="/login/forgotPassword"
                    exact
                    component={ForgotPassword}
                  />
                  <Redirect from="/checkout/login" to="/checkout" />
                  <Route path="/checkout" component={Checkout} />
                  <Route
                    path="/cart"
                    exact
                    render={(props) => <Cart {...props} />}
                  />
                  <Route
                    path="/confirmation/:orderNumber"
                    render={(props) => <Confirmation {...props} />}
                  />
                  <Route
                    path="/"
                    exact
                    render={(props) => <Search {...props} />}
                  />
                  <Route
                    path="/:category"
                    exact
                    render={(props) => <Category {...props} />}
                  />
                  <Route
                    path="/:category/:product"
                    exact
                    render={(props) => <Product {...props} />}
                  />

                  {/* 404/NotFound page, must be LAST */}
                  <Route component={NotFound} />
                </Switch>
              </FullPageLayout>
            )}
          />
        </Switch>
      </ThemeProvider>
    </div>
  </GeolocationProvider>
);

export default Root;
