/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Checkboxoutlineblank(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Bounding box": {},
        check_box_outline_blank: {},
        Checkboxoutlineblank: {},
      },
      variantValues: { color: "Default" },
    },
    {
      overrides: {
        "Bounding box": {},
        check_box_outline_blank: {
          paths: [
            {
              d: "M0 18L0 0L18 0L18 18L0 18ZM2 16L16 16L16 2L2 2L2 16Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Checkboxoutlineblank: {},
      },
      variantValues: { color: "inverse" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Checkboxoutlineblank")}
      {...rest}
    >
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(217,217,217,1)"
        {...getOverrideProps(overrides, "Bounding box")}
      ></View>
      <Icon
        width="18px"
        height="18px"
        viewBox={{ minX: 0, minY: 0, width: 18, height: 18 }}
        paths={[
          {
            d: "M0 18L0 0L18 0L18 18L0 18ZM2 16L16 16L16 2L2 2L2 16Z",
            fill: "rgba(106,51,22,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="12.5%"
        bottom="12.5%"
        left="12.5%"
        right="12.5%"
        {...getOverrideProps(overrides, "check_box_outline_blank")}
      ></Icon>
    </View>
  );
}
