/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesDesktopSnacks(props) {
  const {
    image,
    firstDot,
    secondDot,
    thirdDot,
    fourthDot,
    madeInIndiaImage,
    overrides,
    ...rest
  } = props;
  const buttonFiveSevenZeroFourNineFourSixNineOnClick = useNavigateAction({
    type: "url",
    url: "/sweets",
  });
  const buttonFiveSevenZeroFourNineFourSevenZeroOnClick = useNavigateAction({
    type: "url",
    url: "/savoury",
  });
  return (
    <Flex
      gap="40px"
      direction="row"
      width="unset"
      height="600px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="8px"
      padding="64px 64px 64px 64px"
      backgroundColor="rgba(255,242,224,1)"
      {...getOverrideProps(overrides, "CategoriesDesktopSnacks")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="550px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 508")}
      >
        <Flex
          gap="40px"
          direction="column"
          width="650px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 505")}
        >
          <Flex
            gap="35px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 502")}
          >
            <Flex
              gap="2px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 496")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 507")}
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize="36px"
                  fontWeight="700"
                  color="rgba(21,71,38,1)"
                  lineHeight="54px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.35px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Snacks"
                  {...getOverrideProps(overrides, "Title")}
                ></Text>
                <Image
                  width="127px"
                  height="58px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={madeInIndiaImage}
                  {...getOverrideProps(overrides, "Vector 1")}
                ></Image>
              </Flex>
              <Text
                fontFamily="Montserrat"
                fontSize="18px"
                fontWeight="500"
                color="rgba(21,71,38,1)"
                lineHeight="27px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.35px"
                width="550px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Our snacking range offers artisan sweets and savory treats made with nuts, seeds, lentils, flours, and natural sweeteners like jaggery or dates, paired with fresh seasonings. A perfect mix of taste and nutrition, these snacks bring a healthy twist to satisfy your cravings."
                {...getOverrideProps(overrides, "Text57049450")}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="593px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 501")}
            >
              <Flex
                gap="20px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One")}
              >
                <Flex
                  gap="23px"
                  direction="row"
                  width="437px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 50057049453")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="22px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573413186")}
                  >
                    <Image
                      width="unset"
                      height="22px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={firstDot}
                      {...getOverrideProps(overrides, "Image57049454")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Nutrient-dense and protein-packed."
                    {...getOverrideProps(overrides, "Text57049455")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="24px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Two")}
              >
                <Flex
                  gap="24px"
                  direction="row"
                  width="591px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 50057049457")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="33px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573413187")}
                  >
                    <Image
                      width="unset"
                      height="33px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={secondDot}
                      {...getOverrideProps(overrides, "Image57049458")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="No artificial sweeteners, preservatives, and flavors."
                    {...getOverrideProps(overrides, "Text57049459")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="26px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Three")}
              >
                <Flex
                  gap="26px"
                  direction="row"
                  width="593px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 50057049461")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="35px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573413188")}
                  >
                    <Image
                      width="unset"
                      height="35px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={thirdDot}
                      {...getOverrideProps(overrides, "Image57049462")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Prepared with fresh oil or ghee for superior taste."
                    {...getOverrideProps(overrides, "Text57049463")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="26px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Four")}
              >
                <Flex
                  gap="26px"
                  direction="row"
                  width="593px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 50057049465")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="19.2px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573413189")}
                  >
                    <Image
                      width="unset"
                      height="19.2px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={fourthDot}
                      {...getOverrideProps(overrides, "Image57049466")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="700"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Made in fresh weekly batches or on order in India!"
                    {...getOverrideProps(overrides, "Text57049467")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="29px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 504")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="153px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button573513570")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                alignSelf="stretch"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Browse Sweets"
                onClick={() => {
                  buttonFiveSevenZeroFourNineFourSixNineOnClick();
                }}
                {...getOverrideProps(overrides, "Button57049469")}
              ></Button>
            </Flex>
            <Flex
              gap="8px"
              direction="column"
              width="149px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button573513571")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                alignSelf="stretch"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Browse Savory"
                onClick={() => {
                  buttonFiveSevenZeroFourNineFourSevenZeroOnClick();
                }}
                {...getOverrideProps(overrides, "Button57049470")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        width="468px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Image57049471")}
      >
        <Image
          width="unset"
          height="425.8px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={image}
          {...getOverrideProps(overrides, "Image57049472")}
        ></Image>
      </Flex>
    </Flex>
  );
}
