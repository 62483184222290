/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import Close from "./Close";
import Freeshippingindicator from "./Freeshippingindicator";
import MobileCartListItem from "./MobileCartListItem";
import OrderNote from "./OrderNote";
import Note from "./Note";
export default function Mobile(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="37px"
      direction="column"
      width="319px"
      height="2554px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="32px 24px 32px 24px"
      backgroundColor="rgba(252,242,233,1)"
      {...getOverrideProps(overrides, "Mobile")}
      {...rest}
    >
      <Flex
        gap="295px"
        direction="row"
        width="280px"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 489")}
      >
        <Flex
          gap="32px"
          direction="column"
          width="224px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "section")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(61,12,0,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Your Cart (000)"
            {...getOverrideProps(overrides, "label")}
          ></Text>
        </Flex>
        <Close
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "close")}
        ></Close>
      </Flex>
      <Freeshippingindicator
        display="flex"
        gap="13px"
        direction="column"
        width="280px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(107,71,0,1)"
        padding="0px 0px 0px 0px"
        status="Default"
        {...getOverrideProps(overrides, "Freeshippingindicator")}
      ></Freeshippingindicator>
      <MobileCartListItem
        display="flex"
        gap="8px"
        direction="row"
        width="281px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(137,148,159,1)"
        padding="7.5px 15.5px 7.5px 15.5px"
        backgroundColor="rgba(252,242,233,1)"
        type="Default"
        {...getOverrideProps(overrides, "MobileCartListItem45932314")}
      ></MobileCartListItem>
      <MobileCartListItem
        display="flex"
        gap="8px"
        direction="row"
        width="281px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(137,148,159,1)"
        padding="7.5px 15.5px 7.5px 15.5px"
        backgroundColor="rgba(252,242,233,1)"
        type="Bundle"
        {...getOverrideProps(overrides, "MobileCartListItem45932368")}
      ></MobileCartListItem>
      <Flex
        width="281px"
        height="unset"
        {...getOverrideProps(overrides, "MobileCartListItem45932415")}
      ></Flex>
      <MobileCartListItem
        display="flex"
        gap="8px"
        direction="row"
        width="281px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(137,148,159,1)"
        padding="7.5px 15.5px 7.5px 15.5px"
        backgroundColor="rgba(252,242,233,1)"
        type="Bestseller"
        {...getOverrideProps(overrides, "MobileCartListItem45932462")}
      ></MobileCartListItem>
      <OrderNote
        display="flex"
        gap="8px"
        direction="row"
        width="320px"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="32px 56px 32px 56px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "OrderNote")}
      ></OrderNote>
      <Note
        display="flex"
        gap="8px"
        direction="row"
        width="320px"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="32px 56px 32px 56px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Note")}
      ></Note>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="CHECKOUT"
        {...getOverrideProps(overrides, "Button45932077")}
      ></Button>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="default"
        children="Continue Shopping"
        {...getOverrideProps(overrides, "Button45932078")}
      ></Button>
    </Flex>
  );
}
