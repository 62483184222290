/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import Tooltip2 from "./Tooltip2";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Tooltipmessage1(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="4px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="2px"
      padding="8px 8px 8px 8px"
      backgroundColor="rgba(252,242,233,1)"
      {...getOverrideProps(overrides, "Tooltipmessage1")}
      {...rest}
    >
      <Tooltip2
        width="12px"
        height="12px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "tooltip_2")}
      ></Tooltip2>
      <Text
        fontFamily="Montserrat"
        fontSize="8px"
        fontWeight="500"
        color="rgba(89,84,84,1)"
        lineHeight="9.600000381469727px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="114px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Shipping charges depend on the cart value and delivery country. Changing either may update the cost.&#x2028;BANDWIDTHS"
        {...getOverrideProps(overrides, "Text")}
      ></Text>
    </Flex>
  );
}
