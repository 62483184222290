/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Image, Text } from "@aws-amplify/ui-react";
export default function Section(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Text: {},
        Image: {},
        "Frame 552": {},
        "Line 3": {},
        Section: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        Text: {},
        Image: {},
        "Frame 552": {},
        "Line 3": { display: "none" },
        Section: {},
      },
      variantValues: { state: "hidden_partition" },
    },
    {
      overrides: {
        Text: {},
        Image: { display: "block" },
        "Frame 552": { justifyContent: "space-between", alignItems: "center" },
        "Line 3": {},
        Section: { gap: "16px", justifyContent: "center" },
      },
      variantValues: { state: "Trustmarked" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="7px"
      direction="column"
      width="272px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Section")}
      {...rest}
    >
      <Flex
        gap="150px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 552")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="600"
          color="rgba(61,12,0,1)"
          lineHeight="21px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Title"
          {...getOverrideProps(overrides, "Text")}
        ></Text>
        <Image
          width="40px"
          height="40px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "Image")}
        ></Image>
      </Flex>
      <Icon
        width="unset"
        height="0px"
        viewBox={{ minX: 0, minY: 0, width: 272, height: 1 }}
        paths={[
          {
            d: "M0 0L272 0L272 -1L0 -1L0 0Z",
            stroke: "rgba(220,222,224,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        {...getOverrideProps(overrides, "Line 3")}
      ></Icon>
    </Flex>
  );
}
