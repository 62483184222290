/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Skuselector(props) {
  const {
    onSkuOptionClick,
    onHover,
    onExit,
    priceTxt = "\u20AC 62.99/1 kg",
    beforePriceTxt = "\u20AC 33.59",
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        Price59543163: {},
        Price60403848: {},
        "Frame 579": {},
        Button: {},
        Text: {},
        Skuselector: {},
      },
      variantValues: { state: "selected" },
    },
    {
      overrides: {
        Price59543163: {},
        Price60403848: {},
        "Frame 579": { justifyContent: "center" },
        Button: {
          border: "1px SOLID rgba(21,71,38,1)",
          padding: "7px 7px 7px 7px",
        },
        Text: {},
        Skuselector: {},
      },
      variantValues: { state: "Hover" },
    },
    {
      overrides: {
        Price59543163: {},
        Price60403848: { color: "rgba(89,84,84,1)" },
        "Frame 579": { justifyContent: "center" },
        Button: {
          border: "1px SOLID rgba(89,84,84,1)",
          padding: "7px 7px 7px 7px",
        },
        Text: { color: "rgba(89,84,84,1)", children: "Optional Message" },
        Skuselector: {},
      },
      variantValues: { state: "unselected" },
    },
    {
      overrides: {
        Price59543163: { color: "rgba(137,148,159,1)" },
        Price60403848: { color: "rgba(137,148,159,1)" },
        "Frame 579": { justifyContent: "center" },
        Button: {
          border: "1px SOLID rgba(239,240,240,1)",
          borderRadius: "6px",
          padding: "7px 7px 7px 7px",
          backgroundColor: "rgba(239,240,240,1)",
        },
        Text: { color: "rgba(102,0,0,1)", children: "Out of stock" },
        Skuselector: {},
      },
      variantValues: { state: "Disabled" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="1px"
      direction="column"
      width="165px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Skuselector")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="165px"
        height="34px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="2px SOLID rgba(21,71,38,1)"
        borderRadius="7px"
        padding="6px 6px 6px 6px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        onClick={onSkuOptionClick}
        onMouseEnter={onHover}
        onMouseLeave={onExit}
        {...getOverrideProps(overrides, "Button")}
      >
        <Flex
          gap="2px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 579")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            color="rgba(102,0,0,1)"
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            textDecoration="line-through"
            letterSpacing="0.24px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={beforePriceTxt}
            {...getOverrideProps(overrides, "Price59543163")}
          ></Text>
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            color="rgba(21,71,38,1)"
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.24px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={priceTxt}
            {...getOverrideProps(overrides, "Price60403848")}
          ></Text>
        </Flex>
      </Flex>
      <Text
        fontFamily="Montserrat"
        fontSize="12px"
        fontWeight="600"
        color="rgba(21,71,38,1)"
        lineHeight="14.40000057220459px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="5 left in stock"
        {...getOverrideProps(overrides, "Text")}
      ></Text>
    </Flex>
  );
}
