/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
import Dropdown from "./Dropdown";
export default function ChecloutAccordion(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Irish Customer care": {},
        Dropdown: {},
        "Frame 552": {},
        "Line 3": {},
        ChecloutAccordion: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        "Irish Customer care": { color: "rgba(137,148,159,1)" },
        Dropdown: {},
        "Frame 552": {},
        "Line 3": {},
        ChecloutAccordion: {},
      },
      variantValues: { state: "diabled" },
    },
    {
      overrides: {
        "Irish Customer care": {},
        Dropdown: {},
        "Frame 552": {},
        "Line 3": {},
        ChecloutAccordion: {},
      },
      variantValues: { state: "expand" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "ChecloutAccordion")}
      {...rest}
    >
      <Flex
        gap="150px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 552")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          color="rgba(106,51,22,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Title"
          {...getOverrideProps(overrides, "Irish Customer care")}
        ></Text>
        <Dropdown
          width="16px"
          height="16px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Dropdown")}
        ></Dropdown>
      </Flex>
      <Icon
        width="unset"
        height="0px"
        viewBox={{ minX: 0, minY: 0, width: 43, height: 1 }}
        paths={[
          {
            d: "M0 0L43 0L43 -1L0 -1L0 0Z",
            stroke: "rgba(220,222,224,1)",
            fillRule: "nonzero",
            strokeWidth: 1,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        {...getOverrideProps(overrides, "Line 3")}
      ></Icon>
    </Flex>
  );
}
