import React, { useRef, useState, useEffect } from 'react';
import ProductOptions from '../ProductOptions/ProductOptions';
import ProductQuantity from '../ProductQuantity';
import SkuWithQuantityPickerStyle from './SkuWithQuantityPicker.module.scss';

const SkuWithQuantityPicker = ({
  defaultSku,
  additionalSkus,
  selectedSku,
  onSkuChange,
  productOptionLabel,
  isInProductCard,
  productOptionLabelClass,
  quantityLabel,
  quantityLabelClass,
  quantity,
  updateQuantity,
  allOrderItems,
  editMode
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [tmpSku, setTmpSku] = useState(defaultSku);
  const skuRef = useRef(null);

  useEffect(() => {
    setTmpSku(selectedSku || defaultSku);
  }, [selectedSku, defaultSku]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isFocused) {
        if (e.key === 'ArrowRight') {
          if(!isAddToCartDisabled(
            additionalSkus,
            tmpSku,
            selectedSku,
            quantity,
            allOrderItems,
            editMode
          ) && quantity < 10) updateQuantity(quantity + 1);
        } else if (e.key === 'ArrowLeft') {
          if(quantity > 1) updateQuantity(quantity - 1);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFocused, quantity, updateQuantity, tmpSku]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <span
      ref={skuRef}
      tabIndex={0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={SkuWithQuantityPickerStyle.Sku_section}
    >
      {additionalSkus && (
        <div>
          {productOptionLabel && productOptionLabel !== '' && (
            <p className={productOptionLabelClass ? productOptionLabelClass : ''}>
              {productOptionLabel}
            </p>
          )}
          <ProductOptions
            allOrderItems={allOrderItems}
            editMode={editMode}
            additionalSkus={additionalSkus}
            onSkuChange={onSkuChange}
            selectedSku={selectedSku}
            isInProductCard={isInProductCard}
            quantity={quantity}
          />
        </div>
      )}
      <div>
        <ProductQuantity
          additionalSkus={additionalSkus}
          defaultSku={defaultSku}
          selectedSku={selectedSku}
          allOrderItems={allOrderItems}
          editMode={editMode}
          centered={isInProductCard}
          quantity={quantity}
          updateQuantity={updateQuantity}
          isEverySkuOutOfStock={isEverySkuOutOfStock(additionalSkus)}
        />
      </div>
    </span>
  );
};

const isEverySkuOutOfStock = (
  additionalSkus,
) => {
  const result = additionalSkus?.some(el => 
    (el.inventoryType === 'CHECK_QUANTITY' && el.productQuantityAvailable > 0) || 
    el.inventoryType === 'ALWAYS_AVAILABLE'
  );
  
  if (result) {
    return null;
  } else {
    return true;
  }
}

const isAddToCartDisabled = (
  additionalSkus,
  tmpSku,
  selectedSku,
  quantity,
  allOrderItems,
  editMode
) => {
  var quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == tmpSku?.id)?.quantity;
  if(quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined || editMode == true) quantityOfThisSkuInCart = 0;
  
  return (
    !tmpSku ||
    tmpSku.inventoryType == 'UNAVAILABLE' ||
    (tmpSku.inventoryType == 'CHECK_QUANTITY' &&
      quantity + quantityOfThisSkuInCart >= tmpSku?.productQuantityAvailable)
  );
};

export default SkuWithQuantityPicker;
