/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, useBreakpointValue } from "@aws-amplify/ui-react";
import Carouselindicator from "./Carouselindicator";
export default function Banner(props) {
  const { parentSlides, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: { Carouselindicator: {}, Banner: {} },
      variantValues: { breakpoint: "Base" },
    },
    {
      overrides: { Carouselindicator: {}, Banner: { width: "599px" } },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: { Carouselindicator: {}, Banner: { width: "1279px" } },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: { Carouselindicator: {}, Banner: { width: "1439px" } },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: { Carouselindicator: {}, Banner: { width: "1920px" } },
      variantValues: { breakpoint: "xl" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="64px"
      direction="row"
      width="479px"
      height="560px"
      justifyContent="center"
      alignItems="flex-end"
      position="relative"
      borderRadius="0px 0px 16px 16px"
      padding="64px 16px 64px 16px"
      display="flex"
      {...getOverrideProps(overrides, "Banner")}
      {...rest}
    >
      <Carouselindicator
        display="flex"
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 64px 16px 64px"
        backgroundColor="rgba(221,206,198,1)"
        {...getOverrideProps(overrides, "Carouselindicator")}
      ></Carouselindicator>
    </Flex>
  );
}
