import React, { useEffect } from 'react';
import { parse, stringify } from 'query-string';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import Accordion from 'material-kit/components/Accordion';
import './SortOptions.scss';

const SortOptions = ({ location, history }) => {
  const sortOptions = getSortOptions(location);

  useEffect(() => {
    const { sort } = parse(location.search);

    if (!sort) {
      const defaultSort = defaultSortOptions[0].value;
      const newUrl = {
        ...location,
        search: stringify({
          ...parse(location.search),
          sort: defaultSort,
        }),
      };

      history.push(newUrl);
    }
  }, [location, history]);

  return (
    <div id="sort" styleName="Sort">
      <span className="card-title">
        <h4 styleName="SortBy">Sort By</h4>
      </span>
      <Accordion collapsed={true}>
        <SortOptionsDropdown sortOptions={sortOptions} />
      </Accordion>
    </div>
  );
};

const SortOptionsDropdown = ({ collapsed, sortOptions, toggle }) => (
  <div
    className={classNames({
      dropdown: true,
      panel: true,
      open: !collapsed,
    })}
    styleName="dropdown_div"
  >
    <a
      href="#"
      styleName="Facet__panelHeading"
      className="dropdown-toggle panel-heading"
      onClick={(e) => {
        e.preventDefault();
        toggle();
      }}
    >
      {sortOptions
        .filter((option) => option.status)
        .map((option) => (
          <span key={option.value || 'current'}>{option.label}</span>
        ))}
      <i className="material-icons pull-right">keyboard_arrow_down</i>
    </a>
    <ul className="dropdown-menu" styleName="dropdown-menu_width">
      {sortOptions
        .filter((option) => !option.status)
        .map((option) => (
          <li key={option.value || 'default'}>
            <Link to={option.actionUrl}>{option.label}</Link>
          </li>
        ))}
    </ul>
  </div>
);

const defaultSortOptions = [
  {
    value: 'globalOrderNumber asc',
    label: 'Best match',
  },
  {
    value: 'price asc',
    label: 'Ascending price',
  },
  {
    value: 'price desc',
    label: 'Descending price',
  },
  {
    value: 'sortDate desc',
    label: 'Newly added',
  },
];

const getSortOptions = (location) => {
  const { page, ...searchParams } = parse(location.search);
  const currentSort = searchParams['sort'] || defaultSortOptions[0].value;
  return defaultSortOptions.map((option) => ({
    ...option,
    status: option.value === currentSort,
    actionUrl: {
      ...location,
      search: stringify({
        ...searchParams,
        sort: option.value,
      }),
    },
  }));
};

export default withRouter(SortOptions);
