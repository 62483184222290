/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesTabletFlours(props) {
  const {
    image,
    firstDot,
    secondDot,
    thirdDot,
    fourthDot,
    overrides,
    ...rest
  } = props;
  const buttonFiveSevenFourSevenOneFourTwoSixFourOnClick = useNavigateAction({
    type: "url",
    url: "/flours",
  });
  return (
    <Flex
      gap="16px"
      direction="column"
      width="unset"
      height="445px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="24px 40px 24px 40px"
      backgroundColor="rgba(255,242,224,1)"
      {...getOverrideProps(overrides, "CategoriesTabletFlours")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content574914317")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Content574714233")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 518")}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="20px"
              fontWeight="700"
              color="rgba(21,71,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.35px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Stone-Milled Flours"
              {...getOverrideProps(overrides, "Title")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="500"
            color="rgba(21,71,38,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.1px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Experience the freshness of stone-ground whole grain flours, crafted in Ireland for quality, sustainability, and taste. Packed with nutrients, fiber, flavor, and texture, these flours make every dish wholesome and delicious, ideal for your baking and cooking needs."
            {...getOverrideProps(
              overrides,
              "Experience the freshness of stone-ground whole grain flours, crafted in Ireland for quality, sustainability, and taste. Packed with nutrients, fiber, flavor, and texture, these flours make every dish wholesome and delicious, ideal for your baking and cooking needs."
            )}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="555px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 502")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 516")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="417px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 515")}
            >
              <Flex
                gap="16px"
                direction="row"
                width="410px"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500574714242")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="22px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon574714243")}
                  >
                    <Image
                      width="unset"
                      height="22px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={firstDot}
                      {...getOverrideProps(overrides, "Image574714244")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="21px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Stone-Milled for superior flavor & texture."
                    {...getOverrideProps(overrides, "Text574714245")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="48px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Two")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="36px"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500574714247")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="33px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon574714248")}
                  >
                    <Image
                      width="unset"
                      height="33px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={secondDot}
                      {...getOverrideProps(overrides, "Image574714249")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="21px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="More nutrients are retained."
                    {...getOverrideProps(overrides, "Text574714250")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="47px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Three")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="80px"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500574714252")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="35px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon574714253")}
                  >
                    <Image
                      width="unset"
                      height="35px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={thirdDot}
                      {...getOverrideProps(overrides, "Image574714254")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="21px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Freshly milled in-house in small batches."
                    {...getOverrideProps(overrides, "Text574714255")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="383px"
                height="42px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Four ")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="19.2px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon574714257")}
                >
                  <Image
                    width="unset"
                    height="19.2px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={fourthDot}
                    {...getOverrideProps(overrides, "Image574714258")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="No preservatives, just pure whole grain flour."
                  {...getOverrideProps(overrides, "Text574714259")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="8px"
              direction="column"
              width="114px"
              height="112px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "Image574714260")}
            >
              <Image
                width="110px"
                height="104px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={image}
                {...getOverrideProps(overrides, "Image574914273")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 519")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button574714263")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Browse Stone-Milled Flours"
                onClick={() => {
                  buttonFiveSevenFourSevenOneFourTwoSixFourOnClick();
                }}
                {...getOverrideProps(overrides, "Button574714264")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
