/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function Ellipse(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { "Ellipse ": {}, Button: {}, Ellipse: {} },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        "Ellipse ": {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
              fill: "rgba(219,68,55,1)",
              fillRule: "nonzero",
            },
          ],
          shrink: "0",
        },
        Button: {},
        Ellipse: { width: "8px" },
      },
      variantValues: { state: "Selected" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Ellipse")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="row"
        width="8px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Button")}
      >
        <Icon
          width="unset"
          height="8px"
          viewBox={{ minX: 0, minY: 0, width: 8, height: 8 }}
          paths={[
            {
              d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          {...getOverrideProps(overrides, "Ellipse ")}
        ></Icon>
      </Flex>
    </Flex>
  );
}
