/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesMobileSnacks(props) {
  const {
    image,
    madeInIndiaImage,
    firstDot,
    secondDot,
    thirdDot,
    fourthDot,
    overrides,
    ...rest
  } = props;
  const buttonFiveFourFiveNineTwoTwoZeroNineOnClick = useNavigateAction({
    type: "url",
    url: "/sweets",
  });
  const buttonFiveFourFiveNineTwoTwoOneFourOnClick = useNavigateAction({
    type: "url",
    url: "/savoury",
  });
  return (
    <Flex
      gap="24px"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="32px 16px 32px 16px"
      backgroundColor="rgba(255,242,224,1)"
      {...getOverrideProps(overrides, "CategoriesMobileSnacks")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 528")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="40px"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 513")}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="20px"
              fontWeight="700"
              color="rgba(21,71,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.1px"
              width="81px"
              height="31px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Snacks"
              {...getOverrideProps(overrides, "Title")}
            ></Text>
            <Image
              width="79px"
              height="36.24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={madeInIndiaImage}
              {...getOverrideProps(overrides, "Image54592076")}
            ></Image>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 527")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 526")}
          >
            <Flex
              gap="17px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 520")}
            >
              <Flex
                gap="17px"
                direction="row"
                width="273px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554592205")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="22px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573313139")}
                >
                  <Image
                    width="32px"
                    height="22px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={firstDot}
                    {...getOverrideProps(overrides, "Image54592088")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="224px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Nutrient-dense and protein-packed."
                  {...getOverrideProps(overrides, "Text54592089")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="17px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 521")}
            >
              <Flex
                gap="17px"
                direction="row"
                width="274px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554592203")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="33px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573413140")}
                >
                  <Image
                    width="32px"
                    height="33px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={secondDot}
                    {...getOverrideProps(overrides, "Image54592091")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="225px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="No artificial sweeteners, preservatives, and flavors."
                  {...getOverrideProps(overrides, "Text54592092")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="15px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 522")}
            >
              <Flex
                gap="15px"
                direction="row"
                width="277px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554592204")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="35px"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573413141")}
                >
                  <Image
                    width="unset"
                    height="35px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={thirdDot}
                    {...getOverrideProps(overrides, "Image54592094")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="230px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Prepared with fresh oil or ghee for superior taste."
                  {...getOverrideProps(overrides, "Text54592095")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="14px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 523")}
            >
              <Flex
                gap="14px"
                direction="row"
                width="274px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554592202")}
              >
                <Flex
                  gap="8px"
                  direction="column"
                  width="32px"
                  height="19.2px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573413142")}
                >
                  <Image
                    width="32px"
                    height="19.2px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={fourthDot}
                    {...getOverrideProps(overrides, "Image54592097")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="700"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="225px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Made in fresh weekly batches or on order in India!"
                  {...getOverrideProps(overrides, "Text54592098")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="11px"
        direction="column"
        width="135px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 529")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Browse Sweets"
          onClick={() => {
            buttonFiveFourFiveNineTwoTwoZeroNineOnClick();
          }}
          {...getOverrideProps(overrides, "Button54592209")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Browse Savoury"
          onClick={() => {
            buttonFiveFourFiveNineTwoTwoOneFourOnClick();
          }}
          {...getOverrideProps(overrides, "Button54592214")}
        ></Button>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="148px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 524")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Image58883838")}
        >
          <Image
            width="150px"
            height="141.3px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={image}
            {...getOverrideProps(overrides, "Image58883839")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
