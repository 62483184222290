/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function Twoslidescarouselindicator(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Ellipse 50581625": {},
        "Ellipse 50581626": {},
        "Ellipse 50581627": {},
        "Ellipse 50581628": {},
        Twoslidescarouselindicator: {},
      },
      variantValues: { selction: "Default" },
    },
    {
      overrides: {
        "Ellipse 50581625": {
          width: "8px",
          height: "8px",
          viewBox: { minX: 0, minY: 0, width: 8, height: 8 },
          paths: [
            {
              d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 50581626": {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
              fill: "rgba(219,68,55,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Ellipse 50581627": {},
        "Ellipse 50581628": {},
        Twoslidescarouselindicator: {},
      },
      variantValues: { selction: "Second" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="16px 64px 16px 64px"
      backgroundColor="rgba(221,206,198,1)"
      display="flex"
      {...getOverrideProps(overrides, "Twoslidescarouselindicator")}
      {...rest}
    >
      <Icon
        width="12px"
        height="12px"
        viewBox={{ minX: 0, minY: 0, width: 12, height: 12 }}
        paths={[
          {
            d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
            fill: "rgba(219,68,55,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Ellipse 50581625")}
      ></Icon>
      <Icon
        width="8px"
        height="8px"
        viewBox={{ minX: 0, minY: 0, width: 8, height: 8 }}
        paths={[
          {
            d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Ellipse 50581626")}
      ></Icon>
      <Icon
        width="8px"
        height="8px"
        viewBox={{ minX: 0, minY: 0, width: 8, height: 8 }}
        paths={[
          {
            d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Ellipse 50581627")}
      ></Icon>
      <Icon
        width="8px"
        height="8px"
        viewBox={{ minX: 0, minY: 0, width: 8, height: 8 }}
        paths={[
          {
            d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Ellipse 50581628")}
      ></Icon>
    </Flex>
  );
}
