/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesMobilePulses(props) {
  const { image, firstDot, secondDot, thirdDot, overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="32px 16px 32px 16px"
      backgroundColor="rgba(254,234,210,1)"
      {...getOverrideProps(overrides, "CategoriesMobilePulses")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 528")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="40px"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 513")}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="20px"
              fontWeight="700"
              color="rgba(21,71,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.1px"
              width="219px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Pulses (Coming Soon)"
              {...getOverrideProps(overrides, "Title")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 527")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 526")}
          >
            <Flex
              gap="17px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 520")}
            >
              <Flex
                gap="17px"
                direction="row"
                width="273px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554622624")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="22px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573413147")}
                >
                  <Image
                    width="unset"
                    height="22px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={firstDot}
                    {...getOverrideProps(overrides, "Image54622625")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="224px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rich earthy flavor"
                  {...getOverrideProps(overrides, "Text54622626")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="17px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 521")}
            >
              <Flex
                gap="17px"
                direction="row"
                width="274px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554622628")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="33px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573413148")}
                >
                  <Image
                    width="unset"
                    height="33px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={secondDot}
                    {...getOverrideProps(overrides, "Image54622629")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="225px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Untouched by artificial polishing."
                  {...getOverrideProps(overrides, "Text54622630")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="15px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 522")}
            >
              <Flex
                gap="15px"
                direction="row"
                width="277px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 52554622632")}
              >
                <Flex
                  gap="8px"
                  direction="row"
                  width="32px"
                  height="35px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon573413149")}
                >
                  <Image
                    width="unset"
                    height="35px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={thirdDot}
                    {...getOverrideProps(overrides, "Image54622633")}
                  ></Image>
                </Flex>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.1px"
                  width="230px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Naturally high in protein & fiber."
                  {...getOverrideProps(overrides, "Text54622634")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="11px"
        direction="column"
        width="135px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 529")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          alignSelf="stretch"
          size="small"
          isDisabled={true}
          variation="primary"
          children="Browse Pulses"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="150px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 524")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Image58883852")}
        >
          <Image
            width="150px"
            height="141.3px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={image}
            {...getOverrideProps(overrides, "Image58883853")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
