/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Eurosymbol(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M12 18C10.0333 18 8.28333 17.4417 6.75 16.325C5.21667 15.2083 4.13333 13.7667 3.5 12L0 12L0 10L3.05 10C3.01667 9.81667 3 9.65 3 9.5L3 8.5C3 8.35 3.01667 8.18333 3.05 8L0 8L0 6L3.5 6C4.13333 4.23333 5.21667 2.79167 6.75 1.675C8.28333 0.558333 10.0333 0 12 0C13.15 0 14.2375 0.2 15.2625 0.6C16.2875 1 17.2 1.56667 18 2.3L16.25 4.05C15.6667 3.56667 15.0125 3.1875 14.2875 2.9125C13.5625 2.6375 12.8 2.5 12 2.5C10.75 2.5 9.6125 2.82083 8.5875 3.4625C7.5625 4.10417 6.78333 4.95 6.25 6L12 6L12 8L5.6 8C5.56667 8.18333 5.54167 8.35 5.525 8.5C5.50833 8.65 5.5 8.81667 5.5 9C5.5 9.18333 5.50833 9.35 5.525 9.5C5.54167 9.65 5.56667 9.81667 5.6 10L12 10L12 12L6.25 12C6.78333 13.05 7.5625 13.8958 8.5875 14.5375C9.6125 15.1792 10.75 15.5 12 15.5C12.8 15.5 13.5708 15.3625 14.3125 15.0875C15.0542 14.8125 15.7 14.4333 16.25 13.95L18 15.7C17.2 16.4333 16.2875 17 15.2625 17.4C14.2375 17.8 13.15 18 12 18Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(12.5%, 12.5%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Eurosymbol")}
      {...rest}
    ></Icon>
  );
}
