/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text, View } from "@aws-amplify/ui-react";
import Close from "./Close";
import Freeshippingindicator from "./Freeshippingindicator";
import Addnotes from "./Addnotes";
import Note from "./Note";
export default function Minicart(props) {
  const {
    onNoteClick,
    onClose,
    onCheckoutClick,
    slot,
    yourCartLabel,
    availFreeShippingLabel,
    onContinueShoppingClick,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="540px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="32px 24px 32px 24px"
      backgroundColor="rgba(252,242,233,1)"
      {...getOverrideProps(overrides, "Minicart")}
      {...rest}
    >
      <Flex
        gap="295px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 489")}
      >
        <Flex
          gap="32px"
          direction="column"
          width="224px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "section")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(61,12,0,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={yourCartLabel}
            {...getOverrideProps(overrides, "label4552945")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="24px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          onClick={onClose}
          {...getOverrideProps(overrides, "Button47941944")}
        >
          <Close
            width="unset"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "close")}
          ></Close>
        </Flex>
      </Flex>
      <Freeshippingindicator
        display="flex"
        gap="13px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(107,71,0,1)"
        padding="0px 0px 0px 0px"
        status="Default"
        availFreeShippingLabel={availFreeShippingLabel}
        {...getOverrideProps(overrides, "Freeshippingindicator")}
      ></Freeshippingindicator>
      <View
        width="unset"
        height="193px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(252,242,233,1)"
        children={slot}
        {...getOverrideProps(overrides, "slot")}
      ></View>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="32px 56px 32px 56px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "OrderNote")}
      >
        <Addnotes
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "add_notes")}
        ></Addnotes>
        <Text
          fontFamily="Montserrat"
          fontSize="16px"
          fontWeight="400"
          color="rgba(61,12,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          textDecoration="underline"
          letterSpacing="0.01px"
          width="235px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Leave a Note for Your Order"
          onClick={onNoteClick}
          {...getOverrideProps(overrides, "label47621628")}
        ></Text>
      </Flex>
      <Note
        display="flex"
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="32px 56px 32px 56px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Note")}
      ></Note>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="CHECKOUT"
        onClick={onCheckoutClick}
        {...getOverrideProps(overrides, "Button45721374")}
      ></Button>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="default"
        children="Continue Shopping"
        onClick={onContinueShoppingClick}
        {...getOverrideProps(overrides, "Button45721358")}
      ></Button>
    </Flex>
  );
}
