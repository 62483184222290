/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function MasterCardicon(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="36px"
      height="25.51px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "MasterCardicon")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="36px"
        height="25.51px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        {...getOverrideProps(overrides, "Group40651536")}
      >
        <Icon
          width="36px"
          height="25.51px"
          viewBox={{ minX: 0, minY: 0, width: 36, height: 25.511810302734375 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector40651537")}
        ></Icon>
        <View
          padding="0px 0px 0px 0px"
          width="27.53px"
          height="17.02px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="16.66%"
          bottom="16.64%"
          left="11.73%"
          right="11.81%"
          {...getOverrideProps(overrides, "Group40651538")}
        >
          <Icon
            width="7.44px"
            height="13.37px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 7.440944671630859,
              height: 13.370079040527344,
            }}
            paths={[
              {
                d: "M7.44094 0L0 0L0 13.3701L7.44094 13.3701L7.44094 0Z",
                fill: "rgba(255,95,0,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="10.7%"
            bottom="10.72%"
            left="36.5%"
            right="36.47%"
            {...getOverrideProps(overrides, "Vector40651539")}
          ></Icon>
          <Icon
            width="13.78px"
            height="17.02px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 13.778197288513184,
              height: 17.015745162963867,
            }}
            paths={[
              {
                d: "M10.5184 8.5059C10.5184 5.90748 11.7231 3.42716 13.7546 1.82086C10.0695 -1.08465 4.70733 -0.446853 1.80182 3.26181C-1.08007 6.94685 -0.442274 12.2854 3.26639 15.1909C6.36088 17.624 10.6837 17.624 13.7782 15.1909C11.7231 13.5846 10.5184 11.1043 10.5184 8.5059Z",
                fill: "rgba(235,0,27,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="49.95%"
            {...getOverrideProps(overrides, "Vector40651540")}
          ></Icon>
          <Icon
            width="13.75px"
            height="17.01px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 13.748030662536621,
              height: 17.00984001159668,
            }}
            paths={[
              {
                d: "M13.748 8.5059C13.748 13.2067 9.94488 17.0098 5.2441 17.0098C3.33071 17.0098 1.48819 16.372 0 15.1909C3.68504 12.2854 4.32283 6.94685 1.41732 3.23819C0.992126 2.7185 0.519685 2.22244 0 1.82086C3.68504 -1.08465 9.04724 -0.446853 11.9291 3.26181C13.1102 4.75 13.748 6.59252 13.748 8.5059Z",
                fill: "rgba(247,158,27,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0.03%"
            left="50.05%"
            right="0%"
            {...getOverrideProps(overrides, "Vector40651541")}
          ></Icon>
        </View>
      </View>
    </View>
  );
}
