import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setLocaleCountry } from 'layout/actions';
import { withToggleControls } from 'material-kit/components/Accordion';
import classNames from 'classnames';
import find from 'lodash/find';
import Cookies from 'js-cookie';
import request from 'core/util/superagent';

const LocaleCountrySelector = ({ collapsed, countryCodeFromState, setLocaleCountry }) => {
  const [defaultCountries, setDefaultCountries] = useState([]);

  const fetchLocaleCountries = async () => {
    try {
      const response = await request.get('/api/menu/localeCountries');
      if (response.body && response.body.localeCountryWrappers) {
        setDefaultCountries(
          response.body.localeCountryWrappers
        );
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    fetchLocaleCountries();
  }, []);

  let expires = new Date();
  expires.setTime(expires.getTime() + 10 * 60 * 1000);

  const loadDataOnlyOnce = () => {
    const interval = setInterval(() => {
      const blLocaleDefault = Cookies.get('blLocaleDefault');

      if (blLocaleDefault != null) {
        clearInterval(interval);
        if (Cookies.get('blCountry') === blLocaleDefault) {
          Cookies.set('blLocaleDefault', 'EXPIRED', { expires, path: '/' });
        } else if (blLocaleDefault !== 'EXPIRED') {
          Cookies.set('blLocaleDefault', 'EXPIRED', { expires, path: '/' });
          Cookies.set('blCountry', blLocaleDefault, { expires, path: '/' });
          Cookies.set('blCurrency', 'EUR', { expires, path: '/' });//sefer
          setLocaleCountry(blLocaleDefault, null);
        }
      }
    }, 100);
  };

  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  const currentLocaleSelection = find(defaultCountries, {
    countryCode: countryCodeFromState,
  });

  /*return (
    <li
      className={classNames({
        'dropdown languages': true,
        open: !collapsed,
      })}
    >
      <select
        style={{
          borderRadius: '50px',
          height: '50px',
          paddingLeft: '10',
          border: '1px solid rgba(0,0,0,0.05)',
          width: '100%',
        }}
        value={currentLocaleSelection ? currentLocaleSelection.countryCode : ''}
        onChange={(e) => {
          const selectedCountryCode = e.target.value;
          const selectedCountry = find(defaultCountries, { countryCode: selectedCountryCode });
          setLocaleCountry(selectedCountry.countryCode, selectedCountry.countryCurrency);
        }}
      >
        {defaultCountries.map(({ countryCode, countryName }) => (
          <option key={countryCode} value={countryCode}>
            {countryName}
          </option>
        ))}
      </select>
    </li>
  );*/
  return (<span></span>);
};

const mapStateToProps = (state) => {
  return {
    countryCodeFromState: state.localeCountry.countryCode,
  };
};

export default connect(mapStateToProps, { setLocaleCountry })(
  withToggleControls(LocaleCountrySelector)
);
