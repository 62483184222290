import React, { PureComponent } from 'react';
import ProductQuantityStyle from './ProductQuantity.module.scss';
import classNames from 'classnames';
import { StepperField } from '@aws-amplify/ui-react';

class ProductQuantity extends PureComponent {
  constructor(props) {
    super(props);
  }
  

  render() {
    const handleChange = (e) => {
      e.preventDefault();
    };

    return (
      <div
        className={
          this.props.centered
            ? ProductQuantityStyle.WishlistItem__quantity_centered
            : ProductQuantityStyle.WishlistItem__quantity
        }
      >{
        <StepperField
              width="160px"
              height="unset"
              label="Quantity"
              shrink="0"
              size="small"
              isDisabled={this.props.isEverySkuOutOfStock}
              labelHidden={false}
              onChange={handleChange}
              variation="default"
              onIncrease={() => this.props.updateQuantity(this.props.quantity + 1)}
              onDecrease={() => this.props.updateQuantity(this.props.quantity - 1)}
              defaultValue={1}
              value={this.props.quantity}
              min={1}
              max={isAddToCartDisabled(this.props.additionalSkus,
                this.props.defaultSku,
                this.props.selectedSku,
                this.props.quantity,
                this.props.allOrderItems,
                this.props.editMode)}
              step={1}
              onKeyDown={this.handleKeyDown}
              //{...getOverrideProps(overrides, "StepperField")}
            ></StepperField>
        /*
        <button
          disabled={this.props.quantity <= 1}
          onClick={() => this.props.updateQuantity(this.props.quantity - 1)}
          className={classNames(
            ProductQuantityStyle.WishlistItem__quantity_minus,
            this.props.quantity === 1
              ? ProductQuantityStyle.WishlistItem__quantity_disabled
              : ProductQuantityStyle.WishlistItem__quantity_enabled
          )}
        >
          -
        </button>
        <p className={ProductQuantityStyle.WishlistItem__quantity_number}>
          {this.props.quantity}
        </p>
        <button
          disabled={this.props.disabledAdd || this.props.quantity >= 10}
          onClick={() => this.props.updateQuantity(this.props.quantity + 1)}
          className={classNames(
            ProductQuantityStyle.WishlistItem__quantity_plus,
            (this.props.disabledAdd || this.props.quantity >= 10)
              ? ProductQuantityStyle.WishlistItem__quantity_disabled
              : ProductQuantityStyle.WishlistItem__quantity_enabled
          )}
        >
          +
        </button>*/}
      </div>
    );
  }
}

const isAddToCartDisabled = (
  additionalSkus,
  defaultSku,
  selectedSku,
  quantity,
  allOrderItems,
  editMode
) => {
  var tmpSku = defaultSku;
  if (additionalSkus && additionalSkus.length !== 0) tmpSku = selectedSku;

  var quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == tmpSku?.id)?.quantity;
  if(quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined || editMode == true) quantityOfThisSkuInCart = 0;


   if (
    !tmpSku ||
    tmpSku.inventoryType == 'UNAVAILABLE' ||
    (tmpSku.inventoryType == 'CHECK_QUANTITY' &&
      quantity + quantityOfThisSkuInCart >= tmpSku?.productQuantityAvailable)
  ) {
    return tmpSku?.productQuantityAvailable - quantityOfThisSkuInCart;
  }
  //else if(editMode && quantity > 10) return 99999;
  else return 10;
};

export default ProductQuantity;
