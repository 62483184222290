/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import Tooltip2 from "./Tooltip2";
export default function ShippingbandwidthirelandandUK(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Text55402243: {},
        Text55402245: {},
        slot: {},
        tooltip_2: {},
        "Frame 542": {},
        Text55402247: {},
        Text55402310: {},
        "Frame 549": {},
        ShippingbandwidthirelandandUK: {},
      },
      variantValues: { scale: "Free" },
    },
    {
      overrides: {
        Text55402243: {},
        Text55402245: {},
        slot: {},
        tooltip_2: {},
        "Frame 542": { width: "135px" },
        Text55402247: {
          color: "rgba(89,84,84,1)",
          textDecoration: "line-through",
          children: "\u20AC 9.00",
        },
        Text55402310: { display: "block", children: "\u20AC 5" },
        "Frame 549": {},
        ShippingbandwidthirelandandUK: {},
      },
      variantValues: { scale: "medium" },
    },
    {
      overrides: {
        Text55402243: {},
        Text55402245: {},
        slot: {},
        tooltip_2: {},
        "Frame 542": {},
        Text55402247: { color: "rgba(89,84,84,1)", children: "\u20AC 9.00" },
        Text55402310: {},
        "Frame 549": {},
        ShippingbandwidthirelandandUK: {},
      },
      variantValues: { scale: "high" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="25px"
      direction="row"
      width="250px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "ShippingbandwidthirelandandUK")}
      {...rest}
    >
      <Flex
        gap="4px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 542")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="400"
          color="rgba(89,84,84,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Shipping fee to"
          {...getOverrideProps(overrides, "Text55402243")}
        ></Text>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "slot")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            color="rgba(89,84,84,1)"
            lineHeight="18px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="/*Country"
            {...getOverrideProps(overrides, "Text55402245")}
          ></Text>
        </Flex>
        <Tooltip2
          width="12px"
          height="12px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "tooltip_2")}
        ></Tooltip2>
      </Flex>
      <Flex
        gap="2px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 549")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="500"
          color="rgba(67,168,84,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="FREE"
          {...getOverrideProps(overrides, "Text55402247")}
        ></Text>
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="500"
          color="rgba(67,168,84,1)"
          lineHeight="20px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="FREE"
          {...getOverrideProps(overrides, "Text55402310")}
        ></Text>
      </Flex>
    </Flex>
  );
}
