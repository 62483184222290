import React, { useRef, useState, useEffect } from 'react';
import connectPage from 'page/common/decorators/connectPage';
import { withScroll } from 'core/components/ScrollToTop';
import { SearchCapable } from 'catalog/search/decorator/searchCapable';
import { Helmet } from 'react-helmet';
import ResultList from 'catalog/search/components/ResultList';
// import BannerAdCarouselWidget from 'content/components/widgets/BannerAdCarouselWidget';
// import HomeMiddlePromoWidget from 'content/components/widgets/HomeMiddlePromoWidget';
import queryString from 'query-string';
import classNames from 'classnames';
import HomeStyle from './Home.module.scss';
import NewsletterSubscribe from './NewsletterSubscribe';
import AboutUsWidget from './AboutUsWidget';
import KindOfFood from './KindOfFood';
import BannerWidget from './BannerWidget';
import TestimonialsWidget from './TestimonialsWidget';
import RightHandRelatedProductWidget from 'content/components/widgets/RightHandRelatedProductWidget';
import WhatSetsReddot from './WhatSetsReddot';
// import VideoPlayer from './VideoPlayer';
import Banner from '../../../../ui-components/Banner';
import Carouselindicator from '../../../../ui-components/Carouselindicator';
import Ellipse from '../../../../ui-components/Ellipse';
import Bannerresponsive from '../../../../ui-components/Bannerresponsive';
import Cookies from 'js-cookie';

const Home = ({ isFetching, results, seoProperties }) => {

  const getImageTagsForCountry = (allTagsForAllImages) => {
    let imageTagsForCountry = new Map();
    Array.from(allTagsForAllImages.entries()).forEach(([key, value]) => {
        const countries = value.has("Countries") ? value.get("Countries").split("_") : [];
        const indexes = value.has("Indexes") ? value.get("Indexes").split("_") : [];
        const screenSize = value.get("ScreenSize");
        const path = "/" + key;
        for (let i = 0; i < countries.length; i++) {
        const country = countries[i];
        const index = indexes[i];
        let images = [];
        let excludedImages = [];
        let imageKey = null;
        Array.from(imageTagsForCountry.entries()).forEach(([key, value]) => {
            if (key.Country == country && key.ScreenSize == screenSize) {
            imageKey = key;
            images = value.Included;
            excludedImages = value.Excluded;
            }
        });
        if (index != -1) {
            images[index - 1] = {
            Path: path,
            ButtonLink: value.get("ButtonLink"),
            ShowButton: value.get("ShowButton"),
            ButtonX: value.get("ButtonX"),
            ButtonY: value.get("ButtonY"),
            ButtonText: value.get("ButtonText")
            };
        } else {
            excludedImages.push(path);
        }
        if (!imageKey) {
            imageKey = {Country: country, ScreenSize: screenSize};
        }
        imageTagsForCountry.set(imageKey, {Included: images, Excluded: excludedImages});
        }
    });

    return imageTagsForCountry;
}


  const [clientCountryCode, setClientCountryCode] = useState(null);
  const [screenSize, setScreenSize] = useState(null);
  const [bannerImagesMap, setBannerImagesMap] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [numberOfBannerImages, setNumberOfBannerImages] = useState(0);
  const kindOfFoodRef = useRef(null);
  const resultList = <ResultList numberPerRow={3} results={results} />;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [aspectRatio, setAspectRatio] = useState('2.57');
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [mobileImages, setMobileImages] = useState([]);
  const [buttonLink, setButtonLink] = useState("");
  const [showButton, setShowButton] = useState("false");
  const [buttonText, setButtonText] = useState("");
  const [buttonCoordinates, setButtonCoordinates] = useState({ButtonX: null, ButtonY: null});


  //screenSize = window.innerWidth
  const headerText =
    typeof window !== 'undefined' && window.innerWidth <= 992
      ? 'Best Sellers'
      : 'Quick look Into Best Sellers';

  const handleOnFirstDotClick = () => {
    setSelectedIndex(0);
  }


  const handleOnSecondDotClick = () => {
    setSelectedIndex(1);
  }


  const handleOnThirdDotClick = () => {
    setSelectedIndex(2);
  }


  const handleOnFourthDotClick = () => {
    setSelectedIndex(3);
  }


  useEffect(() => {
    setIsBannerLoading(true);
    let finalImagesMap = new Map();
    fetch("/api/banner/images/tags", {method: "GET"})
    .then((response) => response.json())
    .then((tagsForImages) => {
      let tagsForImagesMap = new Map(Object.entries(tagsForImages));
      Array.from(tagsForImagesMap.entries()).forEach(([key, value]) => {
        const tagsMap = new Map(Object.entries(value));
        tagsForImagesMap.set(key, tagsMap);
      });
      finalImagesMap = getImageTagsForCountry(tagsForImagesMap);
    })
    .catch((err) => {
      console.error("Error while fetching tags for banner images: ", err);
    })
    .finally(() => {
      setBannerImagesMap(finalImagesMap);
      setIsBannerLoading(false);
    });

    const clientCountryCode = Cookies.get('blCountry');
    if (clientCountryCode) {
      setClientCountryCode(clientCountryCode);
    } else {
      setClientCountryCode('IE');
    }
  }, []);


  useEffect(() => {
    if (bannerImagesMap) {
      let foundMatch = false;
      let excludedImages = [];
      Array.from(bannerImagesMap.entries()).forEach(([key, value]) => {
        if (key.Country == clientCountryCode && key.ScreenSize == screenSize) {
          excludedImages = value.Excluded;
          if (value.Included.length > 0) {
            if (screenSize == "Mobile") {
              setMobileImages(value.Included);
            }
            setImagePath(value.Included[selectedIndex].Path);
            setNumberOfBannerImages(value.Included.length);
            setButtonLink(value.Included[selectedIndex].ButtonLink);
            setShowButton(value.Included[selectedIndex].ShowButton);
            setButtonText(value.Included[selectedIndex].ButtonText);
            setButtonCoordinates({ButtonX: value.Included[selectedIndex].ButtonX, ButtonY: value.Included[selectedIndex].ButtonY});
            foundMatch = true;
          }
        }
      });
      if (!foundMatch) {
        Array.from(bannerImagesMap.entries()).forEach(([key, value]) => {
          if (key.Country == "ALL" && key.ScreenSize == screenSize && excludedImages.indexOf(value.Included[selectedIndex].Path) === -1 ) {
            if (value.Included.length > 0) {
              if (screenSize == "Mobile") {
                setMobileImages(value.Included);
              }
              setImagePath(value.Included[selectedIndex].Path);
              setNumberOfBannerImages(value.Included.length);
              setButtonLink(value.Included[selectedIndex].ButtonLink);
              setShowButton(value.Included[selectedIndex].ShowButton);
              setButtonText(value.Included[selectedIndex].ButtonText);
              setButtonCoordinates({ButtonX: value.Included[selectedIndex].ButtonX, ButtonY: value.Included[selectedIndex].ButtonY});
              foundMatch = true;
            }
          }
        });
      }
      if (!foundMatch) {
        if (screenSize == "Mobile") {
          setMobileImages([]);
        }
        setImagePath(null);
        setNumberOfBannerImages(0);
        setButtonLink("");
        setShowButton("false");
        setButtonText("");
        setButtonCoordinates({ButtonX: null, ButtonY: null});
      }

    }
  }, [bannerImagesMap, clientCountryCode, screenSize, selectedIndex]);

  useEffect(() => {
    if (aspectRatio == '16/28') {
        setScreenSize("Mobile");
    } else if (aspectRatio == '16/15') {
        setScreenSize("Tablet");
    } else if (typeof window !== 'undefined') {
        setScreenSize("Desktop");
    }
  }, [aspectRatio]);

  useEffect(() => {
    const updateAspectRatio = () => {
      if (typeof window !== 'undefined' && window.innerWidth < 600) {
        setAspectRatio('16/28');
      } else if (typeof window !== 'undefined' && window.innerWidth < 1000) {
        setAspectRatio('16/15');
      } else {
        setAspectRatio('2.57');
      }
    };

    updateAspectRatio();
    if (window == undefined) return;
    window?.addEventListener('resize', updateAspectRatio);

    return () => {
      window?.removeEventListener('resize', updateAspectRatio);
    };
  }, []);


  let bannerOverrides = {
    Bannerresponsive: {
      display: imagePath ? 'flex' : 'none',
      maxWidth: '100%',
      width: '100%',
      height: 'unset',
      padding: '3.14vw 16px',
      style: {
        backgroundImage: `url("${imagePath}")`,
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
        height: '100%',
        aspectRatio: aspectRatio,
        backgroundRepeat: 'no-repeat',
        padding: '3.14vw 1.6vw'
      }
    },
    Carouselindicator: {
      display: numberOfBannerImages > 1 ? 'flex' : 'none',
      overrides: {
        Button50901688: {
          style: {
            cursor: 'pointer',
          },
        },
        Button50901702: {
          style: {
            cursor: 'pointer',
          },
        },
        Button50901717: {
          style: {
            cursor: 'pointer',
          },
        },
        Button50901733: {
          style: {
            cursor: 'pointer',
          },
        },
      }
    },
    Button: {
      isLoading: isBannerLoading,
      loadingText: "Loading...",
      display: showButton == "true" ?  'flex' : 'none',
      onClick: () => { window.location.href = buttonLink },
      children: buttonText,
      style: {
        position: 'absolute',
        top: `${buttonCoordinates.ButtonY}%`,
        left: `${buttonCoordinates.ButtonX}%`
      }
    }
  }

  let finalBannerContent = 
  <Bannerresponsive
  contentSize={numberOfBannerImages}
  overrides={bannerOverrides}
  selectedIndex={selectedIndex}
  onFirstDotClick={handleOnFirstDotClick}
  onSecondDotClick={handleOnSecondDotClick}
  onThirdDotClick={handleOnThirdDotClick}
  onFourthDotClick={handleOnFourthDotClick}
  ></Bannerresponsive>

  if (screenSize == "Mobile") {
    let mobileBanner = [];
    for (let i = 0; i < mobileImages.length; i++) {
      const mobileImage = mobileImages.at(i);
      
      bannerOverrides = {
        Bannerresponsive: {
          display: mobileImage.Path ? 'flex' : 'none',
          maxWidth: '100%',
          width: '100%',
          height: 'unset',
          padding: '3.14vw 16px',
          style: {
            backgroundImage: `url("${mobileImage.Path}")`,
            backgroundSize: 'contain',
            backgroundPositionX: 'center',
            height: '100%',
            aspectRatio: aspectRatio,
            backgroundRepeat: 'no-repeat',
            padding: '3.14vw 1.6vw'
          }
        },
        Carouselindicator: {
          display: numberOfBannerImages > 1 ? 'flex' : 'none',
          overrides: {
            Button50901688: {
              style: {
                cursor: 'pointer',
              },
            },
            Button50901702: {
              style: {
                cursor: 'pointer',
              },
            },
            Button50901717: {
              style: {
                cursor: 'pointer',
              },
            },
            Button50901733: {
              style: {
                cursor: 'pointer',
              },
            },
          }
        },
        Button: {
          isLoading: isBannerLoading,
          loadingText: "Loading...",
          display: mobileImage.ShowButton == "true" ?  'flex' : 'none',
          onClick: () => { window.location.href =  mobileImage.ButtonLink },
          children: mobileImage.ButtonText,
          style: {
            position: 'absolute',
            top: `${mobileImage.ButtonY}%`,
            left: `${mobileImage.ButtonX}%`
          }
        }
      }
      mobileBanner.push(
        <Bannerresponsive
        contentSize={numberOfBannerImages}
        overrides={bannerOverrides}
        selectedIndex={i}
        onFirstDotClick={handleOnFirstDotClick}
        onSecondDotClick={handleOnSecondDotClick}
        onThirdDotClick={handleOnThirdDotClick}
        onFourthDotClick={handleOnFourthDotClick}
        ></Bannerresponsive>
      )
    }
    finalBannerContent =
    <div className={classNames(HomeStyle.slider)}>
      <div className={classNames(HomeStyle.slides)}>
        {mobileBanner}
      </div>
    </div>
  }


  return (
    <div className={classNames(HomeStyle.Home, 'container-fluid')}>
      <Helmet titleTemplate="%s | Reddot Food | Authentic Indian Snacks & Spices">
        {seoProperties.title ? (
          <title>{seoProperties.title}</title>
        ) : (
          <title>Home</title>
        )}
        {seoProperties.description ? (
          <meta name="description" content={seoProperties.description} />
        ) : (
          <meta
            name="description"
            content="Welcome to Reddot Food! Explore our premium range of authentic Indian snacks and spices. From savory snacks to sweet treats, we offer a wide range of products made withthe finest ingredients. Explore our selection and enjoy traditional flavors delivered fresh toyour door."
          />
        )}
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="author" content="Reddot Food Limited" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Indian snacks, authentic spices, healthy snacks, South Indian food, Reddot Food"
        ></meta>
      </Helmet>

      {/*
                <BannerAdCarouselWidget
                    mcs={[]}
                    scs={[{
                        id: 0,
                        values: {
                            imageUrl: '/cms/static/img/banners/shirt-special.jpg',
                            targetUrl: '/merchandise',
                        }
                    }]}
                />*/}

      {/* Middle promo widget
            <div className='container'>
                <HomeMiddlePromoWidget
                    sc={{
                        values: {
                            htmlContent: `
                                <div id="home_feature">
                                    <h2>HOT SAUCE AFICIONADO?</h2> Click to join our Heat Clinic Frequent Care Program. The place to get all the deals on burn treatment.
                                </div>
                            `
                        }
                    }}
                />

                <hr />

                <div className='row'>
                    <h3 className='text-center'>
                        {`The Heat Clinic's Top Selling Sauces`}
                    </h3>
                    {resultList}
                </div>
            </div>
            */}

      {/* <BannerWidget /> */}
      {/* <Banner parentSlides={"Two"}></Banner> */}
      <div>

      </div>
      <span className={classNames('col-xs-12 col-md-12 col-lg-12', HomeStyle.Banner)}>
        {finalBannerContent}
      </span>
      {/*<KindOfFood kindOfFoodRef={kindOfFoodRef} />*/}

      {/* <VideoPlayer /> */}
      <div
        className={classNames(
          HomeStyle.BestSellerProducts,
          'col-xs-12 col-md-12 col-lg-12'
        )}
      >

        <RightHandRelatedProductWidget
          // resultList={results}
          restrictedWidth={false}
          productKey={'bestSellerProducts'}
          categoryKey=''
          sc={{
            values: {
              headerText: headerText,
              relatedProductsMaxNum: 100,
              relatedProductsType: 'FEATURED',
            },
          }}
        />

        
      </div>
      {/*<WhatSetsReddot />
      <TestimonialsWidget />*/}
      <AboutUsWidget />
      <NewsletterSubscribe kindOfFoodRef={kindOfFoodRef} />
    </div>
  );
};

const getSearchParams = (props) => {
  const { q, page, sort, ...filters } = queryString.parse(
    props.location.search
  );
  return { q, page, sort, category: '', ...filters };
};

export default withScroll(
  connectPage(SearchCapable('/api/catalog/search', getSearchParams)(Home))
);
