/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Tooltip2(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="12px"
      height="12px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Tooltip2")}
      {...rest}
    >
      <View
        width="12px"
        height="12px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(217,217,217,1)"
        {...getOverrideProps(overrides, "Bounding box")}
      ></View>
      <Icon
        width="10px"
        height="10px"
        viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
        paths={[
          {
            d: "M2 6L6 6L6 5L2 5L2 6ZM2 4.5L8 4.5L8 3.5L2 3.5L2 4.5ZM2 3L8 3L8 2L2 2L2 3ZM5 10L3.6625 8L0 8L0 0L10 0L10 8L6.3375 8L5 10ZM5 8.2L5.8 7L9 7L9 1L1 1L1 7L4.2 7L5 8.2Z",
            fill: "rgba(106,51,22,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="8.33%"
        bottom="8.33%"
        left="8.33%"
        right="8.33%"
        {...getOverrideProps(overrides, "tooltip_2")}
      ></Icon>
    </View>
  );
}
