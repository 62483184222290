import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ProductOptionsStyle from './ProductOptions.module.scss';
import ResultListItemStyle from './../../../search/components/ResultListItem/ResultListItem.module.scss';
import { format } from 'layout/util/moneyUtils';
import classNames from 'classnames';
import Skuselector from './../../../../../app/ui-components/Skuselector';

const ProductOptions = ({
  additionalSkus,
  selectedSku,
  onSkuChange,
  isInProductCard,
  allOrderItems,
  editMode,
  quantity
}) => {
  const [hoveredSku, setHoveredSku] = useState(null);
  if (isEmpty(additionalSkus)) {
    return null;
  }

  let quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == selectedSku?.id)?.quantity;
  if (quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) {
    quantityOfThisSkuInCart = 0;
  }

  if (!selectedSku || (selectedSku.productQuantityAvailable <= quantityOfThisSkuInCart && selectedSku.inventoryType == 'CHECK_QUANTITY' && !editMode)) {
    for (let i = 0; i < additionalSkus.length; i++) {
      let quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == additionalSkus[i]?.id)?.quantity;
      if (quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) {
        quantityOfThisSkuInCart = 0;
      }
      if (additionalSkus[i].available && (additionalSkus[i].inventoryType !== 'CHECK_QUANTITY' || quantityOfThisSkuInCart < additionalSkus[i].productQuantityAvailable)) {
        onSkuChange(additionalSkus[i]);
        break;
      }
    }
  }

  const updateSku = (option) => {
    selectedSku = option;
    onSkuChange(selectedSku);
  };

  const updateHoverSku = (option) => {
    setHoveredSku(option);
  };

  const updateHoverExitSku = (option) => {
    setHoveredSku(null);
  };

  return (
    <div
      className={classNames(
        ProductOptionsStyle.Product_option,
        !isInProductCard && additionalSkus && additionalSkus.length === 1
          ? ProductOptionsStyle.Product_option_one_sku_not_card
          : ''
      )}
    >
      {additionalSkus.map((option, index) => {
        let quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == option?.id)?.quantity;
        if (quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) {
          quantityOfThisSkuInCart = 0;
        }
        let exists = quantityOfThisSkuInCart > 0;

        var state = 'unselected';
        var howManyLeft = 0;
        if (option.inventoryType === 'CHECK_QUANTITY') howManyLeft = option.productQuantityAvailable;
        if (!option.available ||
          (option.id !== selectedSku?.id && exists && editMode) ||
          (option.id !== selectedSku?.id &&
            quantityOfThisSkuInCart >= option.productQuantityAvailable &&
            option.inventoryType === 'CHECK_QUANTITY' &&
            !editMode)) {
          state = 'Disabled';
        }
        else if (selectedSku && selectedSku.id === option.id) state = 'selected';
        else if (hoveredSku && hoveredSku.id === option.id) state = 'Hover';
        /*else if (!option.available ||
          (option.id !== selectedSku?.id && exists && editMode) ||
          (option.id !== selectedSku?.id &&
            option.productQuantityAvailable < 10 &&
            option.inventoryType === 'CHECK_QUANTITY' &&
            !editMode)) {
          state = 'Outofstock';
        }*/

        const skuSelectorOverrides = {
          Button: {
            style: {
              cursor: 'pointer',
            },
          },
          Text: {
            children: state != 'Disabled' && howManyLeft > 0 && howManyLeft < 10 ? howManyLeft + ' left in stock' : 
            state == 'Disabled' ? 'Out of stock': '',
          }
        }
        return (
          <div className={classNames(
            ProductOptionsStyle.skuSelectorParent,
            index%2==0 && index==additionalSkus.length-1 && isInProductCard ? ProductOptionsStyle.skuSelectorParentOddOne : ''
          )}>
            <Skuselector
              beforePriceTxt={option.salePrice !== null && format(option.salePrice) !== format(option.retailPrice) ?
                `${format(option.retailPrice)}` : ''}
              priceTxt={option.salePrice !== null && format(option.salePrice) !== format(option.retailPrice) ?
                `${format(option.salePrice)}/${option.productOptionsValue}` :
                  `${format(option.retailPrice)}/${option.productOptionsValue}`
            }

              className={index % 2 == 0 && index + 1 < additionalSkus.length ? ProductOptionsStyle.paddingBetweenSkus : ''}
              overrides={skuSelectorOverrides}
              state={state}
              onSkuOptionClick={() => {
                if (!option.available ||
                  (option.id !== selectedSku?.id && exists && editMode) ||
                  (option.id !== selectedSku?.id &&
                    quantityOfThisSkuInCart >= option.productQuantityAvailable &&
                    option.inventoryType === 'CHECK_QUANTITY' &&
                    !editMode)) return;
                updateSku(option);
              }}
              onHover={() => updateHoverSku(option)}
              onExit={() => updateHoverExitSku(option)}>
            </Skuselector>
          </div>
        );
        {/*<div key={option.id} className={classNames(ProductOptionsStyle.Product_option_box)}>
            <button
              disabled={
                !option.available ||
                (option.id !== selectedSku?.id && exists && editMode) ||
                (option.id !== selectedSku?.id &&
                  quantityOfThisSkuInCart >= option.productQuantityAvailable &&
                  option.inventoryType === 'CHECK_QUANTITY' &&
                  !editMode)
              }
              className={classNames(
                selectedSku && selectedSku.id === option.id
                  ? ProductOptionsStyle.Button__selected
                  : ProductOptionsStyle.Button__not_selected,
                isInProductCard ? ProductOptionsStyle.Button_Font_Small : ''
              )}
              onClick={() => updateSku(option)}
            >
              {option.salePrice !== null && format(option.salePrice) !== format(option.retailPrice) ? (
                <>
                  <span className={ResultListItemStyle.retailPriceLineThrough}>
                    {format(option.retailPrice)}
                  </span>
                  <span>
                    {format(option.salePrice)}/{option.productOptionsValue}
                  </span>
                </>
              ) : (
                <span>
                  {format(option.retailPrice)}/{option.productOptionsValue}
                </span>
              )}
            </button>
          </div>*/}
        // );
      })}
    </div>
  );
};

export default ProductOptions;
