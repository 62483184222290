/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex } from "@aws-amplify/ui-react";
export default function MinicartCheckoutoverlay(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "1": {},
        "2": {},
        "3": {},
        "4": {},
        "5": {},
        "8": {},
        "2.1": {},
        MinicartCheckoutoverlay: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        "1": { display: "none" },
        "2": { display: "flex" },
        "3": {},
        "4": {},
        "5": {},
        "8": {},
        "2.1": { display: "flex" },
        MinicartCheckoutoverlay: {},
      },
      variantValues: { state: "Enter_discount" },
    },
    {
      overrides: {
        "1": { display: "none" },
        "2": { display: "flex" },
        "3": { display: "flex" },
        "4": {},
        "5": {},
        "8": {},
        "2.1": {},
        MinicartCheckoutoverlay: {},
      },
      variantValues: { state: "Invalid_discount" },
    },
    {
      overrides: {
        "1": { display: "none" },
        "2": { display: "flex" },
        "3": {},
        "4": { display: "flex" },
        "5": {},
        "8": {},
        "2.1": {},
        MinicartCheckoutoverlay: {},
      },
      variantValues: { state: "Valid_discount" },
    },
    {
      overrides: {
        "1": { display: "none" },
        "2": { display: "flex" },
        "3": {},
        "4": {},
        "5": { display: "flex" },
        "8": {},
        "2.1": {},
        MinicartCheckoutoverlay: {},
      },
      variantValues: { state: "Expired_discount" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="8px 16px 0px 16px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "MinicartCheckoutoverlay")}
      {...rest}
    >
      <Flex
        width="300px"
        height="unset"
        shrink="0"
        display="flex"
        {...getOverrideProps(overrides, "1")}
      ></Flex>
      <Flex
        width="300px"
        height="unset"
        shrink="0"
        display="none"
        {...getOverrideProps(overrides, "2")}
      ></Flex>
      <Flex
        width="300px"
        height="unset"
        shrink="0"
        display="none"
        {...getOverrideProps(overrides, "2.1")}
      ></Flex>
      <Flex
        width="300px"
        height="unset"
        shrink="0"
        display="none"
        {...getOverrideProps(overrides, "3")}
      ></Flex>
      <Flex
        width="300px"
        height="unset"
        shrink="0"
        display="none"
        {...getOverrideProps(overrides, "4")}
      ></Flex>
      <Flex
        width="300px"
        height="unset"
        shrink="0"
        display="none"
        {...getOverrideProps(overrides, "5")}
      ></Flex>
      <Flex
        width="288px"
        height="unset"
        shrink="0"
        display="flex"
        {...getOverrideProps(overrides, "8")}
      ></Flex>
    </Flex>
  );
}
