/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Bullet4(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="32px"
      height="34.72px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 32, height: 34 }}
      paths={[
        {
          d: "M15.7676 0.0121811C25.6901 -0.408857 33.6758 10.1592 31.6969 20.1799C30.2513 27.0147 24.3287 32.7689 17.9149 34.2847C9.97133 36.3056 2.70141 31.0988 0.764639 23.1131C-0.919512 17.401 0.245359 10.6925 3.59963 5.90671C6.33637 1.83668 11.1643 0.110423 15.7676 0.0121811Z",
          fill: "rgba(219,69,54,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M22.9538 6.42658C24.8204 6.42658 26.6309 7.15638 28.2168 8.15284C28.9746 8.63002 29.7044 9.1914 30.2378 9.9212C31.2061 11.2264 31.4588 13.0228 30.8693 14.5386C30.2237 16.2368 28.6378 17.4437 26.9256 18.0613C25.2134 18.6648 23.3608 18.749 21.5503 18.763C20.0767 18.763 18.4066 18.6507 17.4663 17.5139C16.6242 16.5034 16.6663 15.0298 16.8768 13.7386C17.4663 10.0194 18.5048 6.42658 22.9538 6.42658Z",
          fill: "rgba(219,69,54,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Bullet4")}
      {...rest}
    ></Icon>
  );
}
