/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Flex,
  Image,
  SelectField,
  StepperField,
  Text,
} from "@aws-amplify/ui-react";
import CustomBadges from "./CustomBadges";
export default function MobileCartListItem(props) {
  const {
    title,
    pricePer,
    price,
    onDeleteClick,
    image,
    amount,
    onQuantityDecrease,
    onQuantityIncrease,
    priceLabel,
    savedLabel,
    trashCanIconSrc,
    isNew,
    isSale,
    maxAmount = 10,
    isDisabled,
    onKeyDown,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        Image45721425: {},
        Image45931779: {},
        BadgeCustom50371548: {},
        BadgeCustom49922414: {},
        Optionaltags: {},
        label: {},
        Text45721432: {},
        Text45901490: {},
        "Frame 491": {},
        "Frame 482": {},
        SelectField: {},
        StepperField: {},
        "Frame 483": {},
        "Frame 484": {},
        leftcontent: {},
        Rate: {},
        Image49861716: {},
        Button: {},
        "Frame 486": {},
        MobileCartListItem: {},
      },
      variantValues: { type: "Default" },
    },
    {
      overrides: {
        Image45721425: {},
        Image45931779: {},
        BadgeCustom50371548: { type: "Bestseller" },
        BadgeCustom49922414: {},
        Optionaltags: {},
        label: {},
        Text45721432: {},
        Text45901490: {},
        "Frame 491": {},
        "Frame 482": { width: "182px", padding: "8px 0px 8px 0px" },
        SelectField: {},
        StepperField: {},
        "Frame 483": {},
        "Frame 484": { gap: "4px", width: "177px" },
        leftcontent: {},
        Rate: {},
        Image49861716: {},
        Button: {},
        "Frame 486": {},
        MobileCartListItem: {},
      },
      variantValues: { type: "Bestseller" },
    },
    {
      overrides: {
        Image45721425: {},
        Image45931779: {},
        BadgeCustom50371548: { type: "Bundle" },
        BadgeCustom49922414: {},
        Optionaltags: {},
        label: {},
        Text45721432: { children: "\u20AC00.00/Fixed Selection" },
        Text45901490: {},
        "Frame 491": {},
        "Frame 482": { padding: "8px 0px 8px 0px" },
        SelectField: {},
        StepperField: {},
        "Frame 483": {},
        "Frame 484": { gap: "4px", width: "unset", alignSelf: "stretch" },
        leftcontent: {},
        Rate: {},
        Image49861716: {},
        Button: {},
        "Frame 486": {},
        MobileCartListItem: {},
      },
      variantValues: { type: "Bundle" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="281px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(137,148,159,1)"
      padding="15.5px 15.5px 15.5px 15.5px"
      backgroundColor="rgba(252,242,233,1)"
      display="flex"
      {...getOverrideProps(overrides, "MobileCartListItem")}
      {...rest}
    >
      <Flex
        gap="4px"
        direction="column"
        width="170px"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "leftcontent")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="84px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Image45931779")}
        >
          <Image
            width="unset"
            height="66px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={image}
            {...getOverrideProps(overrides, "Image45721425")}
          ></Image>
        </Flex>
        <Flex
          gap="2px"
          direction="column"
          width="169px"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 484")}
        >
          <Flex
            gap="2px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="2px 0px 2px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 482")}
          >
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Optionaltags")}
            >
              <CustomBadges
                display={isNew == true ? "block" : "none"}
                gap="10px"
                direction="row"
                width="unset"
                height="18px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="32px"
                type="New"
                {...getOverrideProps(overrides, "BadgeCustom50371548")}
              ></CustomBadges>
              <CustomBadges
                display={isSale == true ? "block" : "none"}
                gap="10px"
                direction="row"
                width="unset"
                height="18px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="32px"
                padding="6px 8px 6px 8px"
                backgroundColor="rgba(191,64,64,1)"
                type="Sale"
                {...getOverrideProps(overrides, "BadgeCustom49922414")}
              ></CustomBadges>
            </Flex>
            <Text
              fontFamily="Montserrat"
              fontSize="14px"
              fontWeight="700"
              color="rgba(106,51,22,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="23px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={title}
              {...getOverrideProps(overrides, "label")}
            ></Text>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 491")}
            >
              <Text
                fontFamily="Montserrat"
                fontSize="14px"
                fontWeight="400"
                color="rgba(77,77,77,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={pricePer}
                {...getOverrideProps(overrides, "Text45721432")}
              ></Text>
              <Text
                fontFamily="Montserrat"
                fontSize="12px"
                fontWeight="700"
                color="rgba(63,125,74,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={savedLabel}
                {...getOverrideProps(overrides, "Text45901490")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="169px"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 483")}
          >
            <SelectField
              width="169px"
              height="unset"
              label="Options"
              display="none"
              shrink="0"
              placeholder="Placeholder"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField")}
            ></SelectField>
            <StepperField
              width="169px"
              height="unset"
              label="Quantity"
              shrink="0"
              size="small"
              isDisabled={isDisabled}
              labelHidden={false}
              variation="default"
              defaultValue={amount}
              min={1}
              onDecrease={onQuantityDecrease}
              onIncrease={onQuantityIncrease}
              max={maxAmount}
              onKeyDown={onKeyDown}
              {...getOverrideProps(overrides, "StepperField")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="332px"
        direction="column"
        width="65px"
        height="240px"
        justifyContent="space-between"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 486")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="16px"
          fontWeight="700"
          color="rgba(106,51,22,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="23px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={priceLabel}
          {...getOverrideProps(overrides, "Rate")}
        ></Text>
        <Flex
          gap="8px"
          direction="row"
          width="24px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          onClick={onDeleteClick}
          {...getOverrideProps(overrides, "Button")}
        >
          <Image
            width="16px"
            height="18px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={trashCanIconSrc}
            {...getOverrideProps(overrides, "Image49861716")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
