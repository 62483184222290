/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Cottage(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M3 20L3 10.625L1.2 12L0 10.4L3 8.1L3 5L5 5L5 6.575L11 2L22 10.4L20.8 11.975L19 10.625L19 20L3 20ZM5 18L10 18L10 14L12 14L12 18L17 18L17 9.1L11 4.525L5 9.1L5 18ZM3 4C3 3.16667 3.29167 2.45833 3.875 1.875C4.45833 1.29167 5.16667 1 6 1C6.28333 1 6.52083 0.904167 6.7125 0.7125C6.90417 0.520833 7 0.283333 7 0L9 0C9 0.833333 8.70833 1.54167 8.125 2.125C7.54167 2.70833 6.83333 3 6 3C5.71667 3 5.47917 3.09583 5.2875 3.2875C5.09583 3.47917 5 3.71667 5 4L3 4Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(4.17%, 4.17%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Cottage")}
      {...rest}
    ></Icon>
  );
}
