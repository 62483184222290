/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import Dropdown from "./Dropdown";
import Dropdownclose from "./Dropdownclose";
export default function SubmenuItem(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        label: {},
        Dropdown: {},
        icon51511916: {},
        Dropdownclose: {},
        icon51511918: {},
        Submenu: {},
        SubmenuItem: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        label: { color: "rgba(255,255,255,1)" },
        Dropdown: {},
        icon51511916: {},
        Dropdownclose: {},
        icon51511918: {},
        Submenu: { backgroundColor: "rgba(168,104,0,1)" },
        SubmenuItem: {},
      },
      variantValues: { state: "OnHover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "SubmenuItem")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="4px 16px 4px 16px"
        backgroundColor="rgba(0,0,0,0)"
        display="flex"
        {...getOverrideProps(overrides, "Submenu")}
      >
        <Text
          fontFamily="Caveat Brush"
          fontSize="16px"
          fontWeight="400"
          color="rgba(107,71,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Text"
          {...getOverrideProps(overrides, "label")}
        ></Text>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          borderRadius="4px"
          padding="6px 6px 6px 6px"
          display="none"
          {...getOverrideProps(overrides, "icon51511916")}
        >
          <Dropdown
            width="24px"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Dropdown")}
          ></Dropdown>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          borderRadius="4px"
          padding="6px 6px 6px 6px"
          display="none"
          {...getOverrideProps(overrides, "icon51511918")}
        >
          <Dropdownclose
            width="24px"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Dropdownclose")}
          ></Dropdownclose>
        </Flex>
      </Flex>
    </Flex>
  );
}
