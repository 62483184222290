/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Bullet2(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="32px"
      height="21.34px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 32, height: 21 }}
      paths={[
        {
          d: "M30.9863 3.00698C31.4781 3.82476 31.8041 4.79068 31.7685 5.76846C31.7152 7.07217 31.0218 8.25142 30.1567 9.12846C28.7107 10.5981 26.7789 11.3862 24.8352 11.6351C23.49 11.807 21.9611 11.6588 21.0485 10.5388C20.4381 9.79809 20.2248 8.76698 20.0352 7.78328C19.7329 6.23661 19.49 4.4292 20.4085 3.22031C21.1611 2.23068 22.4826 1.73883 23.4959 1.16402C24.2544 0.731425 25.0485 0.121054 25.9196 0.0203134C27.8159 -0.19302 29.9789 1.30624 30.9922 3.00105L30.9863 3.00698Z",
          fill: "rgba(255,255,255,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M31.9572 8.84812C32.1949 7.81808 31.4127 6.8306 30.341 6.80932C29.347 6.78804 28.3573 6.77101 27.3634 6.7327C26.1101 6.74547 24.8698 6.9668 23.6166 6.86891C20.2587 6.64332 16.8922 6.51989 13.53 6.48158C10.5568 6.46455 7.60947 6.02615 4.64055 5.97933C3.66819 5.98784 2.6872 5.93251 1.71052 5.92825C0.487515 5.924 -0.346549 7.15408 0.141789 8.26074C0.716559 9.56318 1.57655 10.8529 2.268 11.8744C4.87391 15.6115 8.0373 19.4337 12.4928 20.8766C17.3546 22.2386 22.5362 20.3871 26.6374 17.7226C27.5146 17.1012 28.1931 16.2201 28.8457 15.3774C30.3107 13.4407 31.417 11.2189 31.9615 8.84812L31.9572 8.84812Z",
          fill: "rgba(255,255,255,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Bullet2")}
      {...rest}
    ></Icon>
  );
}
