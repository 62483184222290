/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function RevolutPayicon(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="56px"
      height="10.28px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "RevolutPayicon")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="55.99px"
        height="10.28px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0.01%"
        {...getOverrideProps(overrides, "Group")}
      >
        <Icon
          width="2.93px"
          height="7.59px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 2.934936761856079,
            height: 7.587978363037109,
          }}
          paths={[
            {
              d: "M2.93494 2.87805L2.93494 1.62878L1.44291 1.62878L1.44291 0L0 0L0 5.9381C0 6.48371 0.137199 6.89951 0.407898 7.174C0.678495 7.44871 1.10006 7.58798 1.66109 7.58798L2.93494 7.58798L2.93494 6.33871L2.00291 6.33871C1.80014 6.33871 1.65462 6.29415 1.57045 6.20613C1.49306 6.1256 1.44291 5.9221 1.44291 5.68781L1.44291 2.87805L2.93494 2.87805Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="5%"
          bottom="21.17%"
          left="60.4%"
          right="34.36%"
          {...getOverrideProps(overrides, "Vector40651368")}
        ></Icon>
        <Icon
          width="6.02px"
          height="5.91px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 6.015605926513672,
            height: 5.908252239227295,
          }}
          paths={[
            {
              d: "M4.49973 0L3.0078 4.34123L1.51587 0L0 0L2.15377 5.90825L3.86183 5.90825L6.01561 0L4.49973 0Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="20.84%"
          bottom="21.67%"
          left="21.89%"
          right="67.36%"
          {...getOverrideProps(overrides, "Vector40651369")}
        ></Icon>
        <Icon
          width="1.44px"
          height="8.05px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 1.442908525466919,
            height: 8.0504150390625,
          }}
          paths={[
            {
              d: "M1.44291 0L0 0L0 8.05042L1.44291 8.05042L1.44291 0Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="21.67%"
          left="44.77%"
          right="52.65%"
          {...getOverrideProps(overrides, "Vector40651370")}
        ></Icon>
        <Icon
          width="5.5px"
          height="5.98px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 5.497950553894043,
            height: 5.976224899291992,
          }}
          paths={[
            {
              d: "M4.05504 2.95962C4.05504 3.33366 3.99831 3.65906 3.8866 3.92677C3.77571 4.19305 3.61683 4.39718 3.41437 4.53334C3.21191 4.6695 2.963 4.73858 2.67472 4.73858C2.2622 4.73858 1.9509 4.60298 1.74958 4.33574C1.54619 4.06565 1.44301 3.65628 1.44301 3.1191L1.44301 0L0 0L0 3.27858C0 3.7891 0.0638232 4.2221 0.189923 4.56541C0.316024 4.90976 0.489191 5.18988 0.704702 5.39783C0.920008 5.60554 1.17149 5.75554 1.45205 5.84388C1.73128 5.93174 2.03148 5.97622 2.34452 5.97622C2.79568 5.97622 3.16915 5.89442 3.45404 5.73303C3.71394 5.58541 3.93172 5.41279 4.10232 5.21925L4.2315 5.90803L5.49795 5.90803L5.49795 0L4.05504 0L4.05504 2.95962Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="20.85%"
          bottom="21%"
          left="48.95%"
          right="41.23%"
          {...getOverrideProps(overrides, "Vector40651371")}
        ></Icon>
        <Icon
          width="6.1px"
          height="6.04px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 6.1014204025268555,
            height: 6.0444135665893555,
          }}
          paths={[
            {
              d: "M4.65533 0.379842C4.20057 0.127734 3.66071 0 3.05066 0C2.44822 0 1.91021 0.127724 1.45175 0.37976C0.992667 0.632433 0.632042 0.989275 0.379739 1.44034C0.127744 1.89101 0 2.42515 0 3.0279C0 3.62324 0.127744 4.15365 0.379739 4.60431C0.631837 5.05522 0.992667 5.41198 1.45175 5.66465C1.91021 5.91669 2.44822 6.04441 3.05066 6.04441C3.66071 6.04441 4.20067 5.91669 4.65533 5.66457C5.1105 5.41206 5.46928 5.0553 5.72158 4.60431C5.97357 4.1534 6.10142 3.623 6.10142 3.0279C6.10142 2.42539 5.97357 1.89124 5.72158 1.44034C5.46917 0.989193 5.1105 0.632351 4.65533 0.379842ZM3.89307 4.6013C3.66061 4.75266 3.37716 4.82946 3.05076 4.82946C2.73176 4.82946 2.45027 4.75267 2.2139 4.60123C1.97711 4.44963 1.79151 4.23563 1.66191 3.96514C1.5318 3.69441 1.46572 3.37911 1.46572 3.02792C1.46572 2.66948 1.5317 2.35235 1.66191 2.08528C1.7914 1.81844 1.97721 1.60437 2.2142 1.44895C2.45047 1.29368 2.73196 1.21498 3.05076 1.21498C3.37706 1.21498 3.6603 1.29368 3.89277 1.44879C4.12595 1.60429 4.31002 1.81844 4.43971 2.08535C4.56982 2.35306 4.6358 2.67019 4.6358 3.02791C4.6358 3.3783 4.56982 3.69368 4.43971 3.96513C4.30991 4.2357 4.12606 4.4497 3.89307 4.6013Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="20.18%"
          bottom="21%"
          left="32.52%"
          right="56.59%"
          {...getOverrideProps(overrides, "Vector40651372")}
        ></Icon>
        <Icon
          width="1.5px"
          height="6.06px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 1.4995851516723633,
            height: 6.063837051391602,
          }}
          paths={[
            {
              d: "M1.49959 0L0 0L0 6.06384L1.49959 6.06384L1.49959 0Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="19.33%"
          bottom="21.66%"
          left="0%"
          right="97.32%"
          {...getOverrideProps(overrides, "Vector40651373")}
        ></Icon>
        <Icon
          width="6.42px"
          height="8.05px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 6.4245123863220215,
            height: 8.050631523132324,
          }}
          paths={[
            {
              d: "M6.20223 2.33353C6.20223 1.04684 5.15444 0 3.86647 0L0 0L0 1.29498L3.68263 1.29498C4.2655 1.29498 4.74832 1.7529 4.75899 2.31571C4.76433 2.59752 4.65855 2.86348 4.46119 3.06459C4.26375 3.26577 3.99992 3.37663 3.71836 3.37663L2.2838 3.37663C2.23287 3.37663 2.19141 3.41802 2.19141 3.46895L2.19141 4.61988C2.19141 4.63946 2.19746 4.65817 2.20884 4.67392L4.64281 8.05063L6.42451 8.05063L3.98489 4.66468C5.21349 4.60301 6.20223 3.57073 6.20223 2.33353Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="21.66%"
          left="0%"
          right="88.53%"
          {...getOverrideProps(overrides, "Vector40651374")}
        ></Icon>
        <Icon
          width="5.87px"
          height="6.04px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 5.873751640319824,
            height: 6.044496059417725,
          }}
          paths={[
            {
              d: "M4.47267 0.345465C4.04062 0.116193 3.52775 0 2.94817 0C2.36786 0 1.849 0.127806 1.40596 0.379996C0.962215 0.632423 0.613091 0.989347 0.368383 1.44066C0.123911 1.89093 0 2.42881 0 3.03944C0 3.63454 0.125822 4.16479 0.374107 4.61553C0.622957 5.06732 0.981865 5.42225 1.44073 5.67046C1.89872 5.91867 2.44806 6.0445 3.07341 6.0445C3.56952 6.0445 4.01503 5.95155 4.39755 5.76811C4.78016 5.58421 5.09259 5.33376 5.32608 5.02371C5.54786 4.72902 5.6974 4.39574 5.77067 4.03316L5.77859 3.99449L4.34359 3.99449L4.33681 4.01773C4.25788 4.28814 4.10455 4.50182 3.88109 4.65294C3.64123 4.81545 3.34663 4.8979 3.00531 4.8979C2.71603 4.8979 2.45476 4.83591 2.22883 4.71367C2.00402 4.59215 1.82822 4.41835 1.70646 4.19695C1.58423 3.97476 1.51467 3.70745 1.49979 3.40401L1.49979 3.35658L5.83419 3.35658L5.83902 3.33007C5.85412 3.24691 5.86389 3.1612 5.8681 3.07502C5.8717 2.99106 5.87375 2.90718 5.87375 2.82251C5.86604 2.25008 5.73799 1.74658 5.4935 1.32583C5.24798 0.904602 4.90452 0.574737 4.47267 0.345465ZM3.94679 1.45809C4.19334 1.66555 4.34668 1.9604 4.40299 2.33499L1.5496 2.33499C1.58223 2.09195 1.66293 1.88146 1.7897 1.70877C1.92267 1.52764 2.09306 1.38534 2.29615 1.28579C2.49949 1.18592 2.72271 1.13522 2.95954 1.13522C3.35992 1.13523 3.69208 1.24386 3.94679 1.45809Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="20.18%"
          bottom="21%"
          left="11.32%"
          right="78.19%"
          {...getOverrideProps(overrides, "Vector40651375")}
        ></Icon>
        <Icon
          width="5.58px"
          height="7.79px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 5.577904224395752,
            height: 7.7933669090271,
          }}
          paths={[
            {
              d: "M5.5779 2.39368C5.5779 3.85213 4.55359 4.79858 2.99486 4.79858L0.968618 4.79858L0.968618 7.79337L0 7.79337L0 0L2.99486 0C4.55359 0 5.5779 0.935221 5.5779 2.39368ZM2.90575 3.90784C3.93007 3.90784 4.58699 3.31781 4.58699 2.39367C4.58699 1.46969 3.94117 0.890741 2.91685 0.890741L0.968618 0.890741L0.968618 3.90784L2.90575 3.90784Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="2.5%"
          bottom="21.67%"
          left="70.43%"
          right="19.61%"
          {...getOverrideProps(overrides, "Vector40651376")}
        ></Icon>
        <Icon
          width="4.87px"
          height="5.81px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 4.865185260772705,
            height: 5.811493396759033,
          }}
          paths={[
            {
              d: "M0 4.15267C0 3.01709 0.790411 2.38243 2.28224 2.38243L3.95227 2.38243L3.95227 1.99277C3.95227 1.25793 3.42906 0.812714 2.53834 0.812714C1.73662 0.812714 1.20232 1.19114 1.09091 1.77009L0.177998 1.77009C0.3116 0.656821 1.21342 0 2.58274 0C4.03017 0 4.86519 0.723612 4.86519 2.04848L4.86519 5.74471L4.07477 5.74471L3.99677 4.85412C3.64046 5.36612 3.07275 5.81149 2.09293 5.81149C0.846114 5.81148 0 5.18807 0 4.15267ZM2.15973 5.03218C3.29536 5.03218 3.95227 4.37535 3.95227 3.42905L3.95227 3.15066L2.19314 3.15066C1.38042 3.15066 0.923912 3.45122 0.923912 4.09696C0.923912 4.65359 1.40272 5.03218 2.15973 5.03218Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="22.44%"
          bottom="21.02%"
          left="80.83%"
          right="10.48%"
          {...getOverrideProps(overrides, "Vector40651377")}
        ></Icon>
        <Icon
          width="5.38px"
          height="7.9px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 5.377399921417236,
            height: 7.904623985290527,
          }}
          paths={[
            {
              d: "M0.968618 0L2.71655 4.53126L4.40878 0L5.3774 0L2.76105 6.63546C2.40474 7.54836 2.19314 7.90462 1.39173 7.90462L0.300606 7.90462L0.300606 7.0696L1.11332 7.0696C1.63653 7.0696 1.72563 6.93602 1.93724 6.40154L2.21544 5.71133L0 1.01931e-05L0.968618 0Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="23.09%"
          bottom="0%"
          left="90.4%"
          right="0%"
          {...getOverrideProps(overrides, "Vector40651378")}
        ></Icon>
      </View>
    </View>
  );
}
