/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function SnacksMobile(props) {
  const {
    madeInIndiaImage,
    image,
    slot,
    firstDot,
    secondDot,
    thirdDot,
    fourthDot,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        Text54592075: {},
        Image54592076: {},
        "Frame 513": {},
        Text54592077: {},
        Content: {},
        Image54592083: {},
        Image54592082: {},
        Image54592085: {},
        Image54592086: {},
        SliderImage: {},
        "Frame 524": {},
        Image54592088: {},
        Text54592089: {},
        "Frame 52554592205": {},
        "Frame 520": {},
        Image54592091: {},
        Text54592092: {},
        "Frame 52554592203": {},
        "Frame 521": {},
        Image54592094: {},
        Text54592095: {},
        "Frame 52554592204": {},
        "Frame 522": {},
        Image54592097: {},
        Text54592098: {},
        "Frame 52554592202": {},
        "Frame 523": {},
        "Frame 526": {},
        "Frame 527": {},
        "Frame 528": {},
        Button54592209: {},
        Button54592214: {},
        "Frame 529": {},
        SnacksMobile: {},
      },
      variantValues: { tab: "Snacks" },
    },
    {
      overrides: {
        Text54592075: { width: "219px", children: "Stone Milled Flours" },
        Image54592076: { display: "none" },
        "Frame 513": {},
        Text54592077: {
          children:
            "Experience the difference in freshly milled, stone-ground whole grain flours, crafted in Ireland for quality, sustainability, and exceptional taste\u2014preserving nutrients, fiber, flavor, and texture for flours that are both healthy and delicious.",
        },
        Content: {},
        Image54592083: { height: "189px" },
        Image54592082: {},
        Image54592085: {},
        Image54592086: {},
        SliderImage: {},
        "Frame 524": {},
        Image54592088: {},
        Text54592089: {
          children: "Stone-Milled for superior\nflavor & texture.",
        },
        "Frame 52554592205": {},
        "Frame 520": {},
        Image54592091: {},
        Text54592092: { children: "More nutrients are retained." },
        "Frame 52554592203": {},
        "Frame 521": {},
        Image54592094: {},
        Text54592095: { children: "Freshly milled in small batches." },
        "Frame 52554592204": {},
        "Frame 522": {},
        Image54592097: {},
        Text54592098: {
          fontWeight: "500",
          children: "No preservatives, just pure whole grain flour.",
        },
        "Frame 52554592202": {},
        "Frame 523": {},
        "Frame 526": {},
        "Frame 527": {},
        "Frame 528": {},
        Button54592209: { children: "Browse Flours" },
        Button54592214: { display: "none", alignSelf: "stretch" },
        "Frame 529": { width: "136px" },
        SnacksMobile: {},
      },
      variantValues: { tab: "Flours" },
    },
    {
      overrides: {
        Text54592075: {
          width: "219px",
          height: "unset",
          children: "Pulses (Coming Soon)",
        },
        Image54592076: { display: "none" },
        "Frame 513": { height: "34px" },
        Text54592077: {
          children:
            "Bring the true taste of tradition to your table with our unpolished lentils. By retaining natural fiber and nutrients, they offer a healthier, more nourishing option for your meals. The perfect addition to any dish, supporting your family\u2019s well-being.",
        },
        Content: {},
        Image54592083: { width: "unset", alignSelf: "stretch" },
        Image54592082: {},
        Image54592085: {},
        Image54592086: {},
        SliderImage: {},
        "Frame 524": {},
        Image54592088: {},
        Text54592089: { children: "Rich earthy flavor" },
        "Frame 52554592205": {},
        "Frame 520": {},
        Image54592091: {},
        Text54592092: { children: "Untouched by artificial polishing." },
        "Frame 52554592203": {},
        "Frame 521": {},
        Image54592094: {},
        Text54592095: { children: "Naturally high in protein & fiber." },
        "Frame 52554592204": {},
        "Frame 522": {},
        Image54592097: {},
        Text54592098: {
          fontWeight: "500",
          height: "44px",
          children: "No preservatives, just pure whole grain flour.",
        },
        "Frame 52554592202": {},
        "Frame 523": { display: "none" },
        "Frame 526": {},
        "Frame 527": {},
        "Frame 528": {},
        Button54592209: {
          isDisabled: "true",
          alignSelf: "stretch",
          children: "Browse Pulses",
        },
        Button54592214: { display: "none", alignSelf: "stretch" },
        "Frame 529": {},
        SnacksMobile: {},
      },
      variantValues: { tab: "Pulses" },
    },
    {
      overrides: {
        Text54592075: {
          width: "305px",
          height: "unset",
          children: "Indian Spices (Coming Soon)",
        },
        Image54592076: { display: "none" },
        "Frame 513": { height: "34px" },
        Text54592077: {
          children:
            "Our range of whole and powdered spices is carefully sourced and hygienically cleaned to preserve their natural essence and purity.",
        },
        Content: {},
        Image54592083: {},
        Image54592082: {},
        Image54592085: {},
        Image54592086: {},
        SliderImage: {},
        "Frame 524": {},
        Image54592088: {},
        Text54592089: { children: "Rich flavor & aroma." },
        "Frame 52554592205": {},
        "Frame 520": {},
        Image54592091: {},
        Text54592092: { children: "Single origin spices." },
        "Frame 52554592203": {},
        "Frame 521": {},
        Image54592094: {},
        Text54592095: { children: "Finest quality & unadulterated." },
        "Frame 52554592204": {},
        "Frame 522": {},
        Image54592097: {},
        Text54592098: {
          fontWeight: "500",
          height: "44px",
          children: "No preservatives, just pure whole grain flour.",
        },
        "Frame 52554592202": {},
        "Frame 523": { display: "none" },
        "Frame 526": {},
        "Frame 527": {},
        "Frame 528": {},
        Button54592209: {
          isDisabled: "true",
          children: "Browse Indian Spices",
        },
        Button54592214: { display: "none", alignSelf: "stretch" },
        "Frame 529": {},
        SnacksMobile: {},
      },
      variantValues: { tab: "spices" },
    },
    {
      overrides: {
        Text54592075: {
          width: "305px",
          height: "unset",
          children: "Condiments",
        },
        Image54592076: { display: "none" },
        "Frame 513": { height: "34px" },
        Text54592077: {
          children:
            "A delightful selection of pickles, spicy sprinkles, instant mixes and more! Whether you\u2019re enhancing a dish with our tangy pickles, adding a burst of spice with dry toppings, or preparing traditional recipes effortlessly with instant mixes, we ensure each product is crafted to preserve its natural taste and quality.",
        },
        Content: {},
        Image54592083: {},
        Image54592082: {},
        Image54592085: {},
        Image54592086: {},
        SliderImage: {},
        "Frame 524": {},
        Image54592088: {},
        Text54592089: { children: "Only real ingredients." },
        "Frame 52554592205": {},
        "Frame 520": {},
        Image54592091: {},
        Text54592092: {
          children: "No added preservatives or\nartificial flavors.",
        },
        "Frame 52554592203": {},
        "Frame 521": {},
        Image54592094: {},
        Text54592095: { children: "Supports immunity & digestion." },
        "Frame 52554592204": {},
        "Frame 522": {},
        Image54592097: {},
        Text54592098: {
          fontWeight: "500",
          height: "44px",
          children: "No preservatives, just pure whole grain flour.",
        },
        "Frame 52554592202": {},
        "Frame 523": { display: "none" },
        "Frame 526": {},
        "Frame 527": {},
        "Frame 528": {},
        Button54592209: { children: "Browse Condiments" },
        Button54592214: { display: "none", alignSelf: "stretch" },
        "Frame 529": {},
        SnacksMobile: {},
      },
      variantValues: { tab: "Tab6" },
    },
    {
      overrides: {
        Text54592075: {
          width: "305px",
          height: "unset",
          children: "Cold-Pressed Oils (Coming Soon)",
        },
        Image54592076: { display: "none" },
        "Frame 513": { height: "34px" },
        Text54592077: {
          children:
            "Our range of cold-pressed oils is extracted using traditional methods to retain maximum nutrients and authentic flavor, ensuring every drop is pure and natural.",
        },
        Content: {},
        Image54592083: {},
        Image54592082: {},
        Image54592085: {},
        Image54592086: {},
        SliderImage: {},
        "Frame 524": {},
        Image54592088: {},
        Text54592089: { children: "Nutrient rich & pure." },
        "Frame 52554592205": {},
        "Frame 520": {},
        Image54592091: {},
        Text54592092: { children: "Packed with antioxidants." },
        "Frame 52554592203": {},
        "Frame 521": {},
        Image54592094: {},
        Text54592095: { children: "Chemical-free & unrefined." },
        "Frame 52554592204": {},
        "Frame 522": {},
        Image54592097: {},
        Text54592098: {
          fontWeight: "500",
          height: "44px",
          children: "No preservatives, just pure whole grain flour.",
        },
        "Frame 52554592202": {},
        "Frame 523": { display: "none" },
        "Frame 526": {},
        "Frame 527": {},
        "Frame 528": {},
        Button54592209: {
          isDisabled: "true",
          children: "Browse Cold-Pressed Oils",
        },
        Button54592214: { display: "none", alignSelf: "stretch" },
        "Frame 529": {},
        SnacksMobile: {},
      },
      variantValues: { tab: "oils" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="24px"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "SnacksMobile")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 528")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 513")}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="20px"
              fontWeight="700"
              color="rgba(21,71,38,1)"
              lineHeight="54px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.85px"
              width="81px"
              height="31px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Snacks"
              {...getOverrideProps(overrides, "Text54592075")}
            ></Text>
            <Image
              width="79px"
              height="36.24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "Image54592076")}
            ></Image>
          </Flex>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="500"
            color="rgba(21,71,38,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="1.05px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Our snacking range has artisan sweets and savory treats made with wholesome ingredients like nuts, seeds, lentils, flours, and natural sweeteners such as jaggery or dates, paired with fresh seasonings."
            {...getOverrideProps(overrides, "Text54592077")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 527")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="200px"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 524")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Image54592082")}
            >
              <Image
                width="200px"
                height="188.41px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Image54592083")}
              ></Image>
            </Flex>
            <Flex
              gap="72px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              display="flex"
              {...getOverrideProps(overrides, "SliderImage")}
            >
              <Image
                width="42px"
                height="42px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Image54592085")}
              ></Image>
              <Image
                width="42px"
                height="42px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Image54592086")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 526")}
          >
            <Flex
              gap="17px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 520")}
            >
              <Flex
                gap="17px"
                direction="row"
                width="273px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 52554592205")}
              >
                <Image
                  width="32px"
                  height="22px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "Image54592088")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="224px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Nutrient-dense and protein-packed."
                  {...getOverrideProps(overrides, "Text54592089")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="17px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 521")}
            >
              <Flex
                gap="17px"
                direction="row"
                width="274px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 52554592203")}
              >
                <Image
                  width="32px"
                  height="33px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "Image54592091")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="No artificial sweeteners, preservatives, and flavors."
                  {...getOverrideProps(overrides, "Text54592092")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="15px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 522")}
            >
              <Flex
                gap="15px"
                direction="row"
                width="277px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 52554592204")}
              >
                <Image
                  width="32px"
                  height="35px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "Image54592094")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="230px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Prepared with fresh oil or ghee for superior taste."
                  {...getOverrideProps(overrides, "Text54592095")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="14px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 523")}
            >
              <Flex
                gap="14px"
                direction="row"
                width="274px"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 52554592202")}
              >
                <Image
                  width="35px"
                  height="21px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "Image54592097")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="700"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Made in fresh weekly batches or on order!"
                  {...getOverrideProps(overrides, "Text54592098")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="11px"
        direction="column"
        width="135px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 529")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Browse Sweets"
          {...getOverrideProps(overrides, "Button54592209")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Browse Savoury"
          {...getOverrideProps(overrides, "Button54592214")}
        ></Button>
      </Flex>
    </Flex>
  );
}
