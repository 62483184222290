import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { setLocale } from 'layout/actions';

export const GeolocationContext = createContext();

const GeolocationProvider = ({ children }) => {

  const expires = new Date(Date.now() + 10 * 60 * 1000); // 10 minutes

  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        if (Cookies.get('blLocaleDefault') == null) {
        const response = await fetch('/api/menu/geolocation'); // API endpoint

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const geolocationCountryCode = await response.text(); // Get geolocation as text
        //NEW COMMENT var localeCode = geolocationCountryCode.toLowerCase() + '-' + geolocationCountryCode; // 'de-DE'
        //NEW COMMENT if(localeCode == 'en-EN') localeCode = 'en-US';
        Cookies.set('blLocaleDefault', geolocationCountryCode, { expires, path: '/' });
      }
      } catch (error) {
        console.error('Error fetching geolocation:', error);
      } finally {

      }
    };

    fetchGeolocation();
  }, []);



  return (
    <GeolocationContext.Provider value={{ /* Pass any value if needed */ }}>
      {children}
    </GeolocationContext.Provider>
  );
};

export default GeolocationProvider;
