/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isCsrMode } from 'csr/selectors';
import { isPreviewing } from 'preview/selectors';
import { withRouter } from 'react-router-dom';
import ScrollToTop from 'layout/components/ScrollToTop';
import Header from 'layout/components/Header';
import MessagePortal from 'layout/components/MessagePortal';
import Navbar from 'material-kit/components/Navbar';
import FullPageLayoutStyle from './FullPageLayout.module.scss';
import GlobalScss from '../../../layout/style/common.scss';
import classNames from 'classnames';
import TopOptionalInfoWidget from 'content/components/widgets/TopOptionalInfoWidget';
import Footer from '../../../ui-components/Footer';

const footerOverrides = {
  FooterContent: {
    style: {
      display: 'flex',
      'flex-wrap': 'wrap',
    },
  },
  Footer: {
    width: '100%',
    style: {
      width: '100%',
      maxWidth: '1440px',
    },
  },
  legal: {
    width: '200px',
  },
  'contact&help': {
    width: '200px',
  },
  youraccount: {
    width: '200px',
  },
  aboutus: {
    width: '200px',
  },
  Copyrights: {
    maxWidth: '455px',
    width: '100%',
  },
  Facebookicon: {
    style: {
      cursor: 'pointer',
    },
  },
  LinkedInicon: {
    style: {
      cursor: 'pointer',
    },
  },
  Instagramicon: {
    style: {
      cursor: 'pointer',
    },
  },
  Whatsappicon: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470615: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470609: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470621: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470627: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470651: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470645: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470639: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470633: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470669: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470663: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470657: {
    justifyContent: 'flex-start',
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470681: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470675: {
    style: {
      cursor: 'pointer',
    },
  },
};

export const FullPageLayout = ({ children, csrMode, previewing, location }) => {
  const isLoginPage = ['/login', '/register'].includes(location.pathname);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('@aws-amplify/ui-react/styles.css');
    }
  }, []);

  return (
    <div
      className={
        !isLoginPage
          ? [FullPageLayoutStyle.FullPageLayout]
          : classNames(
              FullPageLayoutStyle.FullPageLayout,
              FullPageLayoutStyle.FullPageLayout_backgroundColor
            )
      }
    >
      <TopOptionalInfoWidget />
      {!isLoginPage && (
        <Navbar
          className={
            !previewing && !csrMode
              ? classNames(
                  FullPageLayoutStyle.FullPageLayoutNavbar,
                  FullPageLayoutStyle.FullPageLayout__navbar
                )
              : [FullPageLayoutStyle['FullPageLayout__navbar--preview']]
          }
          top
        >
          <div className={GlobalScss.GridWrapper}>
            <Header />
          </div>
        </Navbar>
      )}
      <section
        className={classNames(
          FullPageLayoutStyle.FullPageLayout__content,
          'main'
        )}
        role="main"
        id="AppContent"
      >
        {children}
      </section>

      <MessagePortal />
      <ScrollToTop />
      {!isLoginPage && (
        <Footer
          overrides={footerOverrides}
          logoSrc="/static/img/Logo_Rdfood_Final.svg"
          revolutLogoSrc="/static/img/RevolutIcon.svg"
          onLinkedinIconClick={() =>
            window.open('https://www.linkedin.com/company/rdfood')
          }
          onInstagramIconClick={() =>
            window.open('https://www.instagram.com/reddot_food/')
          }
          onFacebookIconClick={() =>
            window.open('https://www.facebook.com/reddotfood')
          }
          onWhatsappIconClick={() =>
            window.open('https://api.whatsapp.com/send?phone=353899569854')
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    csrMode: isCsrMode(state),
    previewing: isPreviewing(state),
  };
};

export default withRouter(connect(mapStateToProps)(FullPageLayout));
