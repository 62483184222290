/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import ShippingbandwidthirelandandUK from "./ShippingbandwidthirelandandUK";
export default function Tally(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="8px 0px 8px 0px"
      {...getOverrideProps(overrides, "Tally")}
      {...rest}
    >
      <Flex
        gap="80px"
        direction="row"
        width="250px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 545")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 54355402220")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="400"
            color="rgba(89,84,84,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Subtotal"
            {...getOverrideProps(overrides, "Text55402221")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "slot55402236")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            color="rgba(89,84,84,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="48px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="€ 00.00"
            {...getOverrideProps(overrides, "Text55402222")}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="80px"
        direction="row"
        width="250px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 548")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 54355403138")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="400"
            color="rgba(89,84,84,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Savings "
            {...getOverrideProps(overrides, "Text55403139")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "slot55403140")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            color="rgba(67,168,84,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="48px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="€ 00.00"
            {...getOverrideProps(overrides, "Text55403141")}
          ></Text>
        </Flex>
      </Flex>
      <ShippingbandwidthirelandandUK
        display="flex"
        gap="25px"
        direction="row"
        width="250px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        scale="high"
        {...getOverrideProps(overrides, "shippingbandwidth_irelandandUK")}
      ></ShippingbandwidthirelandandUK>
      <Flex
        gap="127px"
        direction="row"
        width="250px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(220,222,224,1)"
        padding="7px 0px 7px 0px"
        {...getOverrideProps(overrides, "Frame 547")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 544")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="400"
            color="rgba(89,84,84,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Amount Due"
            {...getOverrideProps(overrides, "Text55402232")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "slot55402237")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="600"
            color="rgba(106,51,22,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="57px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="€ 00.00"
            {...getOverrideProps(overrides, "Text55402233")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
