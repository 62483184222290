/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import Addnotes from "./Addnotes";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function OrderNote(props) {
  const { onClick, overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="row"
      width="600px"
      height="unset"
      justifyContent="center"
      alignItems="flex-end"
      position="relative"
      padding="32px 56px 32px 56px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "OrderNote")}
      {...rest}
    >
      <Addnotes
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "add_notes")}
      ></Addnotes>
      <Text
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="400"
        color="rgba(61,12,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        textDecoration="underline"
        letterSpacing="0px"
        width="198px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Leave a Note for Your Order"
        onClick={onClick}
        {...getOverrideProps(overrides, "label")}
      ></Text>
    </Flex>
  );
}
