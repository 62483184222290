/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Image, Text, TextField } from "@aws-amplify/ui-react";
export default function Textfields(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        TextField: { placeholder: "|" },
        "feedback Icon Image": {},
        Text55932734: {
          color: "rgba(89,84,84,1)",
          textAlign: "left",
          display: "none",
          children: "Optional Message",
        },
        Messaging: {},
        Active: {},
        Text55932736: {},
        Fieldname: { display: "flex" },
        Textfields: {},
      },
      variantValues: { state: "Active" },
    },
    {
      overrides: {
        TextField: { placeholder: "Placeholder" },
        "feedback Icon Image": { display: "block" },
        Text55932734: {
          color: "rgba(89,84,84,1)",
          textAlign: "left",
          children: "Optional Message",
        },
        Messaging: { display: "flex" },
        Active: {},
        Text55932736: {},
        Fieldname: { display: "flex" },
        Textfields: {},
      },
      variantValues: { state: "Disabled" },
    },
    {
      overrides: {
        TextField: { placeholder: "Lorem Ipsum" },
        "feedback Icon Image": { display: "block" },
        Text55932734: {
          color: "rgba(102,0,0,1)",
          textAlign: "left",
          children: "Error Message",
        },
        Messaging: { display: "flex" },
        Active: {},
        Text55932736: {},
        Fieldname: { display: "flex" },
        Textfields: {},
      },
      variantValues: { state: "Error" },
    },
    {
      overrides: {
        TextField: { placeholder: "Lorem Ipsum" },
        "feedback Icon Image": { display: "block" },
        Text55932734: {
          color: "rgba(54,94,61,1)",
          textAlign: "left",
          children: "Success Message",
        },
        Messaging: { display: "flex" },
        Active: {},
        Text55932736: {},
        Fieldname: { display: "flex" },
        Textfields: {},
      },
      variantValues: { state: "Success" },
    },
    {
      overrides: {
        TextField: {},
        "feedback Icon Image": {},
        Text55932734: {},
        Messaging: {},
        Active: {},
        Text55932736: {},
        Fieldname: {},
        Textfields: {},
      },
      variantValues: { state: "Default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Textfields")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Active")}
      >
        <TextField
          width="unset"
          height="unset"
          label="Email*"
          placeholder="Field Placeholder"
          shrink="0"
          alignSelf="stretch"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "TextField")}
        ></TextField>
        <Flex
          gap="2px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="2px 8px 2px 8px"
          display="none"
          {...getOverrideProps(overrides, "Messaging")}
        >
          <Image
            width="12px"
            height="12px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "feedback Icon Image")}
          ></Image>
          <Text
            fontFamily="Montserrat"
            fontSize="8px"
            fontWeight="400"
            color="rgba(149,4,4,1)"
            lineHeight="12px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Lorem Ipsum"
            {...getOverrideProps(overrides, "Text55932734")}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="2px 4px 2px 4px"
        backgroundColor="rgba(255,255,255,1)"
        display="none"
        {...getOverrideProps(overrides, "Fieldname")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="8px"
          fontWeight="400"
          color="rgba(137,148,159,1)"
          lineHeight="12px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Title"
          {...getOverrideProps(overrides, "Text55932736")}
        ></Text>
      </Flex>
    </Flex>
  );
}
