/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesTabletSpices(props) {
  const { image, firstDot, secondDot, thirdDot, overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="unset"
      height="445px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="24px 40px 24px 40px"
      backgroundColor="rgba(255,242,224,1)"
      {...getOverrideProps(overrides, "CategoriesTabletSpices")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content574914323")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Content574914324")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 518")}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="20px"
              fontWeight="700"
              color="rgba(21,71,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.35px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Indian Spices (Coming Soon)"
              {...getOverrideProps(overrides, "Title")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="500"
            color="rgba(21,71,38,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.1px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Our range of whole and powdered spices is carefully sourced and hygienically cleaned to preserve their natural essence and purity. Each spice delivers vibrant aroma and flavor, adding an authentic touch to your cooking while meeting the high standards."
            {...getOverrideProps(
              overrides,
              "Our range of whole and powdered spices is carefully sourced and hygienically cleaned to preserve their natural essence and purity. Each spice delivers vibrant aroma and flavor, adding an authentic touch to your cooking while meeting the high standards."
            )}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="555px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 502")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 516")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="417px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 515")}
            >
              <Flex
                gap="16px"
                direction="row"
                width="410px"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500574914333")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="22px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon574914334")}
                  >
                    <Image
                      width="unset"
                      height="22px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={firstDot}
                      {...getOverrideProps(overrides, "Image574914335")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="21px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Rich flavor & aroma."
                    {...getOverrideProps(overrides, "Text574914336")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="48px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Two")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="36px"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500574914338")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="33px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon574914339")}
                  >
                    <Image
                      width="unset"
                      height="33px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={secondDot}
                      {...getOverrideProps(overrides, "Image574914340")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="21px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Single origin spices."
                    {...getOverrideProps(overrides, "Text574914341")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="47px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Three")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="80px"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500574914343")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="35px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon574914344")}
                  >
                    <Image
                      width="unset"
                      height="35px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={thirdDot}
                      {...getOverrideProps(overrides, "Image574914345")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="21px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Finest quality & unadulterated."
                    {...getOverrideProps(overrides, "Text574914346")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              gap="8px"
              direction="column"
              width="114px"
              height="112px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "Image574914351")}
            >
              <Image
                width="110px"
                height="104px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={image}
                {...getOverrideProps(overrides, "Image574914352")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 519")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button574914354")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={true}
                variation="primary"
                children="Browse Indian Spices"
                {...getOverrideProps(overrides, "Button574914355")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
