/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Loyalty(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Loyalty")}
      {...rest}
    >
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(217,217,217,1)"
        {...getOverrideProps(overrides, "Bounding box")}
      ></View>
      <Icon
        width="20.8px"
        height="20.83px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 20.80000114440918,
          height: 20.825000762939453,
        }}
        paths={[
          {
            d: "M10.825 20.825L0 10L0 0L10 0L20.8 10.85L10.825 20.825ZM10.825 18L17.975 10.85L9.15 2L2 2L2 9.15L10.825 18ZM4.5 6C4.91667 6 5.27083 5.85417 5.5625 5.5625C5.85417 5.27083 6 4.91667 6 4.5C6 4.08333 5.85417 3.72917 5.5625 3.4375C5.27083 3.14583 4.91667 3 4.5 3C4.08333 3 3.72917 3.14583 3.4375 3.4375C3.14583 3.72917 3 4.08333 3 4.5C3 4.91667 3.14583 5.27083 3.4375 5.5625C3.72917 5.85417 4.08333 6 4.5 6ZM11 15.5L14.5 12C14.6833 11.8167 14.8292 11.6 14.9375 11.35C15.0458 11.1 15.1 10.8333 15.1 10.55C15.1 9.98333 14.9 9.5 14.5 9.1C14.1 8.7 13.6167 8.5 13.05 8.5C12.7333 8.5 12.4208 8.59167 12.1125 8.775C11.8042 8.95833 11.4333 9.26667 11 9.7C10.5 9.23333 10.1083 8.91667 9.825 8.75C9.54167 8.58333 9.25 8.5 8.95 8.5C8.38333 8.5 7.9 8.7 7.5 9.1C7.1 9.5 6.9 9.98333 6.9 10.55C6.9 10.8333 6.95417 11.1 7.0625 11.35C7.17083 11.6 7.31667 11.8167 7.5 12L11 15.5Z",
            fill: "rgba(106,51,22,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="8.33%"
        bottom="4.9%"
        left="8.33%"
        right="5%"
        {...getOverrideProps(overrides, "loyalty")}
      ></Icon>
    </View>
  );
}
