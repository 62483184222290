/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import Ellipse from "./Ellipse";
import { Flex } from "@aws-amplify/ui-react";
export default function Carouselindicator(props) {
  const {
    selectedIndex,
    onFirstDotClick,
    onThirdDotClick,
    onFourthDotClick,
    contentSize = 4,
    onSecondDotClick,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="16px 64px 16px 64px"
      backgroundColor="rgba(221,206,198,1)"
      {...getOverrideProps(overrides, "Carouselindicator")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 514")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="8px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display={contentSize < 1 ? "none" : "flex"}
          onClick={onFirstDotClick}
          {...getOverrideProps(overrides, "Button50901688")}
        >
          <Ellipse
            display="flex"
            gap="8px"
            direction="row"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            state={selectedIndex == 0 ? "Selected" : "Default"}
            {...getOverrideProps(overrides, "Ellipse50821591")}
          ></Ellipse>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="8px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display={contentSize < 2 ? "none" : "flex"}
          onClick={onSecondDotClick}
          {...getOverrideProps(overrides, "Button50901702")}
        >
          <Ellipse
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            state={selectedIndex == 1 ? "Selected" : "Default"}
            {...getOverrideProps(overrides, "Ellipse50821588")}
          ></Ellipse>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="8px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display={contentSize < 3 ? "none" : "flex"}
          onClick={onThirdDotClick}
          {...getOverrideProps(overrides, "Button50901717")}
        >
          <Ellipse
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            state={selectedIndex == 2 ? "Selected" : ""}
            {...getOverrideProps(overrides, "Ellipse50821594")}
          ></Ellipse>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="8px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display={contentSize < 4 ? "none" : "flex"}
          onClick={onFourthDotClick}
          {...getOverrideProps(overrides, "Button50901733")}
        >
          <Ellipse
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            state={selectedIndex == 3 ? "Selected" : "Default"}
            {...getOverrideProps(overrides, "Ellipse50821597")}
          ></Ellipse>
        </Flex>
      </Flex>
    </Flex>
  );
}
