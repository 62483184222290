/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Bullet3(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="32px"
      height="32px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
      paths={[
        {
          d: "M0 16.0048C0.0635383 11.7036 0.961017 7.71193 4.00291 4.7122C8.09319 0.680819 14.3597 -0.914283 19.8557 0.51416C25.6536 2.13306 30.7287 6.9501 31.8406 13.1083C32.9684 21.0758 28.0125 30.0274 20.0622 31.7812C14.0102 32.8208 6.77477 30.075 2.82746 25.2659C0.738633 22.8296 0.0953075 19.0284 0.00794229 15.9969L0 16.0048Z",
          fill: "rgba(231,164,35,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M17.0486 15.4551C22.2633 14.9812 23.5207 7.6053 18.6917 5.52045C16.9638 4.77022 15.0352 4.43854 13.0373 4.62017C11.3711 4.77022 9.82054 5.26773 8.43972 6.03376C3.49501 8.77407 5.76295 16.4817 11.3634 15.9763L17.0486 15.463L17.0486 15.4551Z",
          fill: "rgba(231,164,35,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Bullet3")}
      {...rest}
    ></Icon>
  );
}
