/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CategoriesDesktopCondiments(props) {
  const {
    image,
    firstDot,
    secondDot,
    thirdDot,
    onLeftClick,
    onRightClick,
    sliderImage,
    overrides,
    ...rest
  } = props;
  const buttonFiveSevenThreeFiveOneThreeSixZeroNineOnClick = useNavigateAction({
    type: "url",
    url: "/condiments",
  });
  return (
    <Flex
      gap="40px"
      direction="row"
      width="unset"
      height="600px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="8px"
      padding="64px 64px 64px 64px"
      backgroundColor="rgba(252,242,233,1)"
      {...getOverrideProps(overrides, "CategoriesDesktopCondiments")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="550px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 508")}
      >
        <Flex
          gap="40px"
          direction="column"
          width="650px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 505")}
        >
          <Flex
            gap="35px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 502")}
          >
            <Flex
              gap="2px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 496")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 507")}
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize="36px"
                  fontWeight="700"
                  color="rgba(21,71,38,1)"
                  lineHeight="54px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.35px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Condiments"
                  {...getOverrideProps(overrides, "Title")}
                ></Text>
              </Flex>
              <Text
                fontFamily="Montserrat"
                fontSize="18px"
                fontWeight="500"
                color="rgba(21,71,38,1)"
                lineHeight="27px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.35px"
                width="550px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Discover a delightful range of pickles, spicy sprinkles, and instant mixes. From tangy pickles to flavorful toppings and traditional instant recipes, each product is crafted to preserve natural taste and quality, bringing convenience and deliciousness to your meals."
                {...getOverrideProps(overrides, "Text573513585")}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="593px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 501")}
            >
              <Flex
                gap="20px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One")}
              >
                <Flex
                  gap="23px"
                  direction="row"
                  width="437px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513588")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="22px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513589")}
                  >
                    <Image
                      width="unset"
                      height="22px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={firstDot}
                      {...getOverrideProps(overrides, "Image573513590")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Only real ingredients."
                    {...getOverrideProps(overrides, "Text573513591")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="24px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Two")}
              >
                <Flex
                  gap="24px"
                  direction="row"
                  width="591px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513593")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="33px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513594")}
                  >
                    <Image
                      width="unset"
                      height="33px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={secondDot}
                      {...getOverrideProps(overrides, "Image573513595")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="No added preservatives or artificial flavors."
                    {...getOverrideProps(overrides, "Text573513596")}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="26px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Three")}
              >
                <Flex
                  gap="26px"
                  direction="row"
                  width="593px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 500573513598")}
                >
                  <Flex
                    gap="8px"
                    direction="row"
                    width="32px"
                    height="35px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Icon573513599")}
                  >
                    <Image
                      width="unset"
                      height="35px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      objectFit="cover"
                      src={thirdDot}
                      {...getOverrideProps(overrides, "Image573513600")}
                    ></Image>
                  </Flex>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(21,71,38,1)"
                    lineHeight="27px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.35px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Supports immunity & digestion."
                    {...getOverrideProps(
                      overrides,
                      "Supports immunity & digestion."
                    )}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="29px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 504")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button573513608")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                alignSelf="stretch"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Browse Condiments"
                onClick={() => {
                  buttonFiveSevenThreeFiveOneThreeSixZeroNineOnClick();
                }}
                {...getOverrideProps(overrides, "Button573513609")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        width="468px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Image573513612")}
      >
        <Image
          width="468px"
          height="440.87px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={image}
          {...getOverrideProps(overrides, "Image573513620")}
        ></Image>
      </Flex>
    </Flex>
  );
}
