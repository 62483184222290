/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Visaicon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="36px"
      height="22.1px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 36, height: 22 }}
      paths={[
        {
          d: "M7.75408 0.116577L5.08036 6.49577L3.33596 6.49577L2.02021 1.40488C1.94033 1.09132 1.87087 0.97644 1.62793 0.844333C1.23129 0.629155 0.576291 0.427272 0 0.301973L0.0391426 0.116577L2.8471 0.116577C3.20502 0.116577 3.52678 0.354837 3.60804 0.767005L4.30293 4.45833L6.02031 0.116471L7.75408 0.116471L7.75408 0.116577ZM14.5889 4.41302C14.596 2.72935 12.2608 2.6366 12.2769 1.88449C12.2819 1.65559 12.4998 1.41222 12.9769 1.3501C13.2133 1.31915 13.8648 1.29554 14.6037 1.63559L14.8936 0.282827C14.4965 0.138701 13.9856 0 13.35 0C11.7189 0 10.571 0.867095 10.5613 2.10871C10.5508 3.02707 11.3806 3.53954 12.0058 3.8447C12.649 4.15721 12.8649 4.35803 12.8624 4.63756C12.8578 5.06547 12.3494 5.25437 11.8743 5.26182C11.0448 5.27458 10.5635 5.03739 10.1799 4.85901L9.88076 6.25655C10.2663 6.43344 10.978 6.58778 11.7159 6.59554C13.4496 6.59554 14.5836 5.73919 14.5889 4.41302ZM18.8961 6.49577L20.4224 6.49577L19.0901 0.116577L17.6814 0.116577C17.3647 0.116577 17.0975 0.301016 16.9792 0.584588L14.5029 6.49577L16.2357 6.49577L16.5797 5.54294L18.6969 5.54294L18.8961 6.49577ZM17.0548 4.23549L17.9234 1.84034L18.4233 4.23549L17.0548 4.23549ZM10.1119 0.116577L8.74733 6.49577L7.09716 6.49577L8.46227 0.116577L10.1119 0.116577Z",
          fill: "rgba(20,52,203,1)",
          fillRule: "nonzero",
          style: { transform: "translate(21.64%, 35.08%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Visaicon")}
      {...rest}
    ></Icon>
  );
}
