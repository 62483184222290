/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Delete(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Delete")}
      {...rest}
    >
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(217,217,217,1)"
        {...getOverrideProps(overrides, "Bounding box")}
      ></View>
      <Icon
        width="16px"
        height="18px"
        viewBox={{ minX: 0, minY: 0, width: 16, height: 18 }}
        paths={[
          {
            d: "M1 18L1 3L0 3L0 1L5 1L5 0L11 0L11 1L16 1L16 3L15 3L15 18L1 18ZM3 16L13 16L13 3L3 3L3 16ZM5 14L7 14L7 5L5 5L5 14ZM9 14L11 14L11 5L9 5L9 14Z",
            fill: "rgba(106,51,22,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="12.5%"
        bottom="12.5%"
        left="16.67%"
        right="16.67%"
        {...getOverrideProps(overrides, "delete")}
      ></Icon>
    </View>
  );
}
