/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Bullet1(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="34.74px"
      height="20.43px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 34, height: 20 }}
      paths={[
        {
          d: "M3.77755 20.0899C4.9189 20.0899 6.01615 20.531 6.98106 21.1431C7.44422 21.4353 7.89083 21.7772 8.21615 22.2183C8.80612 23.0123 8.95499 24.1095 8.60211 25.0303C8.20512 26.0669 7.24572 26.8057 6.20362 27.1752C5.16151 27.5446 4.03119 27.5942 2.92843 27.6052C2.02968 27.6107 1.00963 27.5336 0.4362 26.8443C-0.0765818 26.2268 -0.0490129 25.3336 0.0778041 24.5451C0.430686 22.2789 1.07028 20.0899 3.78307 20.0899L3.77755 20.0899Z",
          fill: "rgba(255,255,255,1)",
          fillRule: "nonzero",
          style: { transform: "translate(100%, 0%)" },
        },
        {
          d: "M5.8783 2.07642C5.92793 0.686952 7.27329 -0.294501 8.61314 0.080436C17.2753 2.52855 21.2618 10.0769 20.2803 19.5937C19.8943 22.5436 19.1334 25.5431 17.4352 27.9967C15.7204 30.3346 13.5094 32.4188 10.9014 33.6539C9.62216 34.2273 8.24923 34.4919 6.8763 34.7125C5.57504 34.922 4.38958 33.9075 4.3951 32.5897C4.41715 27.9857 4.82517 23.3376 5.0788 18.7446C5.48131 13.1977 5.67981 7.6288 5.88382 2.07642L5.8783 2.07642Z",
          fill: "rgba(255,255,255,1)",
          fillRule: "nonzero",
          style: { transform: "translate(100%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Bullet1")}
      {...rest}
    ></Icon>
  );
}
