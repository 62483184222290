/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, View } from "@aws-amplify/ui-react";
export default function Sliders(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="72px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Sliders")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="40px"
        height="40px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group52091664")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="16px"
          height="8px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="40%"
          bottom="40%"
          left="30.01%"
          right="29.99%"
          {...getOverrideProps(overrides, "Group52091665")}
        >
          <Icon
            width="16px"
            height="8px"
            viewBox={{ minX: 0, minY: 0, width: 16, height: 8 }}
            paths={[
              {
                d: "M15.8052 3.52859L12.2765 0L11.3339 0.942617L13.7236 3.3332L0 3.3332L0 4.6666L13.7236 4.6666L11.3331 7.05719L12.2759 8L15.8046 4.47121C16.065 4.21102 16.065 3.78902 15.8052 3.52859Z",
                fill: "rgba(106,51,22,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector52091666")}
          ></Icon>
        </View>
        <Icon
          width="40px"
          height="40px"
          viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
          paths={[
            {
              d: "M39.35 20C39.35 30.6867 30.6867 39.35 20 39.35L20 40.65C31.4047 40.65 40.65 31.4047 40.65 20L39.35 20ZM20 39.35C9.31329 39.35 0.65 30.6867 0.65 20L-0.65 20C-0.65 31.4047 8.59532 40.65 20 40.65L20 39.35ZM0.65 20C0.65 9.31329 9.31329 0.65 20 0.65L20 -0.65C8.59532 -0.65 -0.65 8.59532 -0.65 20L0.65 20ZM20 0.65C30.6867 0.65 39.35 9.31329 39.35 20L40.65 20C40.65 8.59532 31.4047 -0.65 20 -0.65L20 0.65Z",
              stroke: "rgba(106,51,22,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector52091667")}
        ></Icon>
      </Flex>
      <Flex
        padding="0px 0px 0px 0px"
        width="40px"
        height="40px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group52091668")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="16px"
          height="8px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="40%"
          bottom="40%"
          left="30.01%"
          right="29.99%"
          {...getOverrideProps(overrides, "Group52091669")}
        >
          <Icon
            width="16px"
            height="8px"
            viewBox={{ minX: 0, minY: 0, width: 16, height: 8 }}
            paths={[
              {
                d: "M15.8052 3.52859L12.2765 0L11.3339 0.942617L13.7236 3.3332L0 3.3332L0 4.6666L13.7236 4.6666L11.3331 7.05719L12.2759 8L15.8046 4.47121C16.065 4.21102 16.065 3.78902 15.8052 3.52859Z",
                fill: "rgba(106,51,22,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector52091670")}
          ></Icon>
        </View>
        <Icon
          width="40px"
          height="40px"
          viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
          paths={[
            {
              d: "M39.35 20C39.35 30.6867 30.6867 39.35 20 39.35L20 40.65C31.4047 40.65 40.65 31.4047 40.65 20L39.35 20ZM20 39.35C9.31329 39.35 0.65 30.6867 0.65 20L-0.65 20C-0.65 31.4047 8.59532 40.65 20 40.65L20 39.35ZM0.65 20C0.65 9.31329 9.31329 0.65 20 0.65L20 -0.65C8.59532 -0.65 -0.65 8.59532 -0.65 20L0.65 20ZM20 0.65C30.6867 0.65 39.35 9.31329 39.35 20L40.65 20C40.65 8.59532 31.4047 -0.65 20 -0.65L20 0.65Z",
              stroke: "rgba(106,51,22,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector52091671")}
        ></Icon>
      </Flex>
    </Flex>
  );
}
