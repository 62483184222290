/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import TabLabelMobile from "./TabLabelMobile";
import { Flex } from "@aws-amplify/ui-react";
export default function TabMenu(props) {
  const {
    onFloursClick,
    onSnacksClick,
    onCondimentsClick,
    onSpicesClick,
    onPulsesClick,
    onOilsClick,
    activeTab = 0,
    isIreland,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="720px"
      height="66px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="16px 0px 16px 0px"
      {...getOverrideProps(overrides, "TabMenu")}
      {...rest}
    >
      <TabLabelMobile
        display={isIreland == true ? "flex" : "none"}
        gap="8px"
        direction="column"
        width="unset"
        height="50px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        state={activeTab == 0 ? "Onselect" : "Default1"}
        label="Stone Milled Flours"
        onClick={onFloursClick}
        {...getOverrideProps(overrides, "TabLabelMobile50831601")}
      ></TabLabelMobile>
      <TabLabelMobile
        display="flex"
        gap="8px"
        direction="column"
        width="unset"
        height="50px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 4px 16px 4px"
        state={activeTab == 1 ? "Onselect" : "Default1"}
        label="Snacks"
        onClick={onSnacksClick}
        {...getOverrideProps(overrides, "TabLabelMobile50831605")}
      ></TabLabelMobile>
      <TabLabelMobile
        display="flex"
        gap="8px"
        direction="column"
        width="unset"
        height="50px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 4px 16px 4px"
        state={activeTab == 2 ? "Onselect" : "Default1"}
        label="Condiments"
        onClick={onCondimentsClick}
        {...getOverrideProps(overrides, "TabLabelMobile50831606")}
      ></TabLabelMobile>
      <TabLabelMobile
        display={isIreland == true ? "flex" : "none"}
        gap="8px"
        direction="column"
        width="unset"
        height="50px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 4px 16px 4px"
        state={activeTab == 3 ? "Onselect" : "Default1"}
        label="Indian Spices"
        onClick={onSpicesClick}
        {...getOverrideProps(overrides, "TabLabelMobile50831603")}
      ></TabLabelMobile>
      <TabLabelMobile
        display={isIreland == true ? "flex" : "none"}
        gap="8px"
        direction="column"
        width="unset"
        height="50px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 4px 16px 4px"
        state={activeTab == 4 ? "Onselect" : "Default1"}
        label="Pulses"
        onClick={onPulsesClick}
        {...getOverrideProps(overrides, "TabLabelMobile50831602")}
      ></TabLabelMobile>
      <TabLabelMobile
        display={isIreland == true ? "flex" : "none"}
        gap="8px"
        direction="column"
        width="unset"
        height="50px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="16px 4px 16px 4px"
        state={activeTab == 5 ? "Onselect" : "Default1"}
        label="Cold Pressed Oils"
        onClick={onOilsClick}
        {...getOverrideProps(overrides, "TabLabelMobile50831604")}
      ></TabLabelMobile>
    </Flex>
  );
}
