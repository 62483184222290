/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import CartBadge from "./CartBadge";
import Localmall from "./Localmall";
import { Flex } from "@aws-amplify/ui-react";
export default function Cart(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { CartBadge: {}, local_mall: {}, Cart: {} },
      variantValues: { property1: "Default" },
    },
    {
      overrides: { CartBadge: {}, local_mall: {}, Cart: {} },
      variantValues: { property1: "Variant2" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Cart")}
      {...rest}
    >
      <CartBadge
        display="flex"
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="32px"
        padding="5px 8px 5px 8px"
        backgroundColor="rgba(191,64,64,1)"
        device="Desktop"
        {...getOverrideProps(overrides, "CartBadge")}
      ></CartBadge>
      <Localmall
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        color="Default"
        {...getOverrideProps(overrides, "local_mall")}
      ></Localmall>
    </Flex>
  );
}
