import React, { useEffect, useState } from 'react';
import Freeshippingindicatormini from '../../../ui-components/Freeshippingindicatormini';
import request from 'core/util/superagent';
import Cookies from 'js-cookie';

const FreeshippingindicatorminiWrapper = ({ cartAmount }) => {
  const [fulfillmentOptions, setFulfillmentOptions] = useState([]);
  const [retailMIN, setRetailMIN] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(null);

  const fetchFulfillmentOptions = async () => {
    try {
      const cookieCountryCode = Cookies.get('blCountry');

      const response = await request
        .get('/api/shipping/options')
        .query({ fulfillmentType: 'PHYSICAL_SHIP' });

      if (response.body && Array.isArray(response.body)) {
        const selectedOption = response.body.find(option =>
          option.priceBand === true &&
          option.countryCode === cookieCountryCode
        ) || response.body.find(option =>
          option.priceBand === true &&
          option.countryCode === null
        );

        if (selectedOption) {
          setRetailMIN(selectedOption.retailMIN);
          setShippingPrice(selectedOption.shippingPrice);
        }
      }
    } catch (error) {
      console.error('Error fetching fulfillment options:', error);
    }
  };

  useEffect(() => {
    fetchFulfillmentOptions();
  }, []);

  const freeshippingindicatorminiOverrides = {
    Freeshippingindicatormini: {
      width: '100%',
      style: {
        width: '100%',
        maxWidth: '752px',
      },
    }
  };

  const getShippingState = () => {
    if(shippingPrice == 0) {
      if (!cartAmount || cartAmount === 0) {
        return { state: "default", message: `FREE Shipping On Orders Above €${retailMIN}`};
      } else if (cartAmount > 0 && cartAmount < retailMIN) {
        const difference = (retailMIN - cartAmount).toFixed(2);
        return { state: "ongoing", message: `Add €${difference} more to get FREE Shipping` };
      } else {
        return { state: "success" };
      }
    }
  };

  const { state, message } = getShippingState() || {};

  if (!state) return null;

  const overrides = {
    ...freeshippingindicatorminiOverrides,
    ...((state === 'ongoing' || state === 'default') && {
      Message: {
        children: message,
      },
    }),
  };

  return (
      <Freeshippingindicatormini
        state={state}
        overrides={overrides}
      />
  );
};

export default FreeshippingindicatorminiWrapper;
