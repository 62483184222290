/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Googlepayicon(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="36px"
      height="24.42px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Googlepayicon")}
      {...rest}
    >
      <Icon
        width="24.75px"
        height="13.16px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 24.745885848999023,
          height: 13.162704467773438,
        }}
        paths={[
          {
            d: "M18.1645 0L6.58135 0C2.96161 0 0 2.96161 0 6.58135C0 10.2011 2.96161 13.1627 6.58135 13.1627L18.1645 13.1627C21.7843 13.1627 24.7459 10.2011 24.7459 6.58135C24.7459 2.96161 21.7843 0 18.1645 0Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="22.91%"
        bottom="23.18%"
        left="15.6%"
        right="15.66%"
        {...getOverrideProps(overrides, "Base_1_")}
      ></Icon>
      <Icon
        width="24.75px"
        height="13.16px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 24.745885848999023,
          height: 13.162704467773438,
        }}
        paths={[
          {
            d: "M18.1645 0.533089C18.9773 0.533089 19.7671 0.694333 20.5108 1.01024C21.2314 1.31627 21.8764 1.75393 22.4358 2.31005C22.992 2.86618 23.4296 3.51444 23.7356 4.2351C24.0516 4.97879 24.2128 5.76855 24.2128 6.58135C24.2128 7.39415 24.0516 8.18391 23.7356 8.9276C23.4296 9.64826 22.992 10.2932 22.4358 10.8526C21.8797 11.4088 21.2314 11.8464 20.5108 12.1525C19.7671 12.4684 18.9773 12.6296 18.1645 12.6296L6.58135 12.6296C5.76856 12.6296 4.97879 12.4684 4.2351 12.1525C3.51444 11.8464 2.86947 11.4088 2.31005 10.8526C1.75393 10.2965 1.31627 9.64826 1.01024 8.9276C0.694333 8.18391 0.533089 7.39415 0.533089 6.58135C0.533089 5.76855 0.694333 4.97879 1.01024 4.2351C1.31627 3.51444 1.75393 2.86947 2.31005 2.31005C2.86618 1.75393 3.51444 1.31627 4.2351 1.01024C4.97879 0.694333 5.76856 0.533089 6.58135 0.533089L18.1645 0.533089ZM18.1645 0L6.58135 0C2.96161 0 0 2.96161 0 6.58135C0 10.2011 2.96161 13.1627 6.58135 13.1627L18.1645 13.1627C21.7843 13.1627 24.7459 10.2011 24.7459 6.58135C24.7459 2.96161 21.7843 0 18.1645 0Z",
            fill: "rgba(60,64,67,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="22.91%"
        bottom="23.18%"
        left="15.6%"
        right="15.66%"
        {...getOverrideProps(overrides, "Outline")}
      ></Icon>
      <View
        padding="0px 0px 0px 0px"
        width="17.21px"
        height="6.75px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="38.33%"
        bottom="34.02%"
        left="26.07%"
        right="26.12%"
        {...getOverrideProps(overrides, "G_Pay_Lockup_1_")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="9.81px"
          height="6.39px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="5.31%"
          bottom="0%"
          left="43%"
          right="0%"
          {...getOverrideProps(overrides, "Pay_Typeface_3_")}
        >
          <Icon
            width="3.21px"
            height="4.92px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 3.211700439453125,
              height: 4.916271209716797,
            }}
            paths={[
              {
                d: "M0.631809 2.92541L0.631809 4.91627L0 4.91627L0 0L1.67495 0C2.09945 0 2.46143 0.1415 2.75759 0.424498C3.06033 0.707496 3.2117 1.05302 3.2117 1.46106C3.2117 1.87898 3.06033 2.2245 2.75759 2.5042C2.46472 2.78391 2.10274 2.92212 1.67495 2.92212L0.631809 2.92212L0.631809 2.92541ZM0.631809 0.605485L0.631809 2.31993L1.68812 2.31993C1.93821 2.31993 2.14881 2.23437 2.31335 2.06655C2.48117 1.89872 2.56673 1.6947 2.56673 1.46435C2.56673 1.23729 2.48117 1.03656 2.31335 0.868739C2.14881 0.694333 1.9415 0.608775 1.68812 0.608775L0.631809 0.608775L0.631809 0.605485Z",
                fill: "rgba(60,64,67,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="23.11%"
            left="0%"
            right="67.26%"
            {...getOverrideProps(overrides, "Letter_p_3_")}
          ></Icon>
          <Icon
            width="2.98px"
            height="3.58px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 2.978062152862549,
              height: 3.5835461616516113,
            }}
            paths={[
              {
                d: "M1.46764 0C1.93492 0 2.30347 0.125046 2.57331 0.375137C2.84314 0.625228 2.97806 0.967459 2.97806 1.40183L2.97806 3.47495L2.37587 3.47495L2.37587 3.00768L2.34954 3.00768C2.08958 3.39269 1.74077 3.58355 1.3064 3.58355C0.934552 3.58355 0.625229 3.47495 0.375138 3.25448C0.125047 3.034 0 2.76088 0 2.43181C0 2.083 0.131627 1.80658 0.394881 1.60256C0.658135 1.39525 1.01024 1.29324 1.4479 1.29324C1.82303 1.29324 2.13236 1.36234 2.37258 1.50055L2.37258 1.35576C2.37258 1.13528 2.28702 0.951005 2.11261 0.796343C1.93821 0.641681 1.73419 0.565996 1.50055 0.565996C1.14845 0.565996 0.868739 0.714076 0.664717 1.01353L0.108592 0.664716C0.414625 0.220475 0.86874 0 1.46764 0ZM0.651554 2.44168C0.651554 2.60622 0.720658 2.74442 0.862158 2.85302C1.00037 2.96161 1.1649 3.01755 1.35247 3.01755C1.61901 3.01755 1.85594 2.91883 2.06325 2.72139C2.27057 2.52395 2.37587 2.2936 2.37587 2.02706C2.17843 1.8724 1.9053 1.79342 1.5532 1.79342C1.29653 1.79342 1.08263 1.85594 0.911518 1.9777C0.737112 2.10603 0.651554 2.26069 0.651554 2.44168Z",
                fill: "rgba(60,64,67,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="22.54%"
            bottom="21.41%"
            left="34.62%"
            right="35.02%"
            {...getOverrideProps(overrides, "Letter_a_3_")}
          ></Icon>
          <Icon
            width="3.36px"
            height="4.84px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 3.3630716800689697,
              height: 4.843875885009766,
            }}
            paths={[
              {
                d: "M3.36307 0L1.25704 4.84388L0.605485 4.84388L1.38867 3.14918L0 0L0.687752 0L1.68812 2.41536L1.70128 2.41536L2.67532 0L3.36307 0Z",
                fill: "rgba(60,64,67,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="24.24%"
            bottom="0%"
            left="65.72%"
            right="0%"
            {...getOverrideProps(overrides, "Letter_y_3_")}
          ></Icon>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="5.52px"
          height="5.64px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="16.53%"
          left="0%"
          right="67.93%"
          {...getOverrideProps(overrides, "G_Mark_1_")}
        >
          <Icon
            width="2.7px"
            height="2.67px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 2.700986862182617,
              height: 2.66544771194458,
            }}
            paths={[
              {
                d: "M2.70099 0.592651C2.70099 0.386655 2.68256 0.189543 2.64834 0L0 0L0 1.08592L1.52523 1.08625C1.46336 1.44757 1.26428 1.75558 0.959232 1.96091L0.959232 2.66545L1.86713 2.66545C2.39726 2.17481 2.70099 1.44954 2.70099 0.592651Z",
                fill: "rgba(66,133,244,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="40.63%"
            bottom="12.08%"
            left="51.06%"
            right="0%"
            {...getOverrideProps(overrides, "Blue_500")}
          ></Icon>
          <Icon
            width="4.39px"
            height="2.28px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 4.386142730712891,
              height: 2.2791225910186768,
            }}
            paths={[
              {
                d: "M3.47825 0.893418C3.22552 1.06388 2.90007 1.16358 2.51934 1.16358C1.78388 1.16358 1.15996 0.668007 0.936526 0L0 0L0 0.726582C0.463985 1.64731 1.41762 2.27912 2.51934 2.27912C3.2808 2.27912 3.92051 2.0287 4.38614 1.59762L3.47825 0.893418Z",
                fill: "rgba(52,168,83,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="59.57%"
            bottom="0%"
            left="5.43%"
            right="15.1%"
            {...getOverrideProps(overrides, "Green_500_1_")}
          ></Icon>
          <Icon
            width="1.24px"
            height="2.53px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.2363063097000122,
              height: 2.531846284866333,
            }}
            paths={[
              {
                d: "M1.14812 1.26592C1.14812 1.07835 1.17938 0.897039 1.23631 0.726582L1.23631 0L0.29978 0C0.107934 0.380731 0 0.810494 0 1.26592C0 1.72135 0.108263 2.15111 0.29978 2.53185L1.23631 1.80527C1.17938 1.63481 1.14812 1.45349 1.14812 1.26592Z",
                fill: "rgba(250,187,5,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="27.54%"
            bottom="27.54%"
            left="0%"
            right="77.6%"
            {...getOverrideProps(overrides, "Yellow_500_1_")}
          ></Icon>
          <Icon
            width="4.41px"
            height="2.28px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 4.405557632446289,
              height: 2.2791225910186768,
            }}
            paths={[
              {
                d: "M2.51934 1.11554C2.93495 1.11554 3.30713 1.25868 3.60099 1.53839L4.40556 0.734479C3.91689 0.279379 3.27982 0 2.51934 0C1.41795 0 0.463985 0.63181 0 1.55254L0.936526 2.27912C1.15996 1.61112 1.78388 1.11554 2.51934 1.11554Z",
                fill: "rgba(233,66,53,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="59.57%"
            left="5.43%"
            right="14.75%"
            {...getOverrideProps(overrides, "Red_500")}
          ></Icon>
        </View>
      </View>
    </View>
  );
}
