/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Feedback(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="12px"
      height="12px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Feedback")}
      {...rest}
    >
      <View
        width="12px"
        height="12px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(217,217,217,1)"
        {...getOverrideProps(overrides, "Bounding box")}
      ></View>
      <Icon
        width="10px"
        height="10px"
        viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
        paths={[
          {
            d: "M5 6.5C5.14167 6.5 5.26042 6.45208 5.35625 6.35625C5.45208 6.26042 5.5 6.14167 5.5 6C5.5 5.85833 5.45208 5.73958 5.35625 5.64375C5.26042 5.54792 5.14167 5.5 5 5.5C4.85833 5.5 4.73958 5.54792 4.64375 5.64375C4.54792 5.73958 4.5 5.85833 4.5 6C4.5 6.14167 4.54792 6.26042 4.64375 6.35625C4.73958 6.45208 4.85833 6.5 5 6.5ZM4.5 4.5L5.5 4.5L5.5 1.5L4.5 1.5L4.5 4.5ZM0 10L0 0L10 0L10 8L2 8L0 10ZM1.575 7L9 7L9 1L1 1L1 7.5625L1.575 7Z",
            fill: "rgba(102,0,0,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="8.33%"
        bottom="8.33%"
        left="8.33%"
        right="8.33%"
        {...getOverrideProps(overrides, "feedback")}
      ></Icon>
    </View>
  );
}
